import styled from "@styles/themedComponents";

export const TopSideFooterStyle = styled.article`
  .section-wrap,
  .company-info,
  .terms-and-condition-wrap .terms-and-condition-content {
    max-width: 1920px;
    margin: 0 auto;
  }

  .section-wrap {
    position: relative;
    padding: 48px 64px 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .section-menu-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .section {
      &.logo-wrapper,
      &.notice-wrapper {
        display: flex;
        flex-direction: column;
        gap: 39px;
      }
      .section-title {
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        font-weight: 500;
      }

      .section-list {
        margin-top: 12px;
      }

      &.legal-notice {
        .section-list {
          li {
            display: block;
            font-size: 11px;
            line-height: 17px;
            margin: 0;
            opacity: 0.7;

            &:not(:last-child) {
              margin-bottom: 3px;
            }
          }
        }
      }

      &.global-btn, &.magazine-btn {
        .section-list {
          display: flex;
          gap: 20px;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1px;
          }

          img {
            margin-left: 1px;
          }

          span {
            display: inline-block;
            height: 14px;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: -0.38px;
          }
        }
      }

      &.social-btn {
        text-align: right;

        .section-list {
          display: flex;
          column-gap: 16px;
          height: 24px;

          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  @media (max-width: 1636px) {
    .section-wrap {
      row-gap: 33px;
      .section {
        &:nth-child(3) {
          width: 100%;
          margin-bottom: 24px;
          order: 5;
        }
      }
    }
  }
`;

export const NoticeWrapperStyle = styled.div`
  display: flex;
  gap: 120px;
`;

export const ChannelWrapperStyle = styled.div`
  display: flex;
  gap: 64px;
`;
