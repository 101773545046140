import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { LikeProductResponseType, productApiV2 } from "@amondz/apis-v2";
import { AxiosError } from "axios";

interface IUseLikeProductProps {
  onSuccessCallback?: () => void;
  onErrorCallback?: (variables: number) => void;
  onMutateCallback?: (variables: number) => void;
}

export const useLikeProduct = ({
  onSuccessCallback,
  onErrorCallback,
  onMutateCallback,
}: IUseLikeProductProps): UseMutationResult<LikeProductResponseType, AxiosError, number, unknown> => {
  return useMutation({
    mutationFn: async (productId: number): Promise<LikeProductResponseType> => productApiV2.likeProduct(productId),
    onSuccess: (data) => {
      if (data && onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error, variables) => {
      onErrorCallback && onErrorCallback(variables);
    },
    onMutate: (variables) => {
      onMutateCallback && onMutateCallback(variables);
    },
  });
};
