import { useInfiniteQuery } from "@tanstack/react-query";
import { GetReviewListResponseType, productApiV2, ProductReviewListRequestType } from "@amondz/apis-v2";
import { productQueryKeys } from "./keys";
import { InfiniteQueryOptions } from "../type";

export const useProductReviewsInfinity = (
  payload: ProductReviewListRequestType,
  options?: InfiniteQueryOptions<GetReviewListResponseType, Error>,
) => {
  return useInfiniteQuery<GetReviewListResponseType, Error>(
    productQueryKeys.productReviewsInfinite(payload),
    ({ pageParam = 0 }) =>
      productApiV2.getProductReviewList({
        productId: payload.productId,
        params: {
          ...payload.params,
          page: pageParam + 1,
        },
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalReviewsFetched = allPages.reduce((total, page) => total + page.productReviews.length, 0);
        if (totalReviewsFetched < lastPage.totalCount) {
          return allPages.length;
        }
        return undefined;
      },
      enabled: !!payload.productId,
      ...options,
    },
  );
};
