import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { reviewAPI, ReviewMutationResponseType } from "@amondz/apis-v1";
import { AxiosError } from "axios";

interface IReviewLikeMutate {
  onSuccessCallback?: (reviewId: number) => void;
  onFailureCallback?: (data: { status: number }) => void;
}

export const useLikeReview = ({
  onSuccessCallback,
  onFailureCallback,
}: IReviewLikeMutate): UseMutationResult<ReviewMutationResponseType, AxiosError, number, unknown> => {
  return useMutation({
    mutationFn: async (reviewId: number) => await reviewAPI.likeReview({ reviewId }),
    onSuccess: (data, variables) => {
      if (data.status !== 1 && onFailureCallback) {
        onFailureCallback(data);
        return;
      }
      if (data.status === 1 && onSuccessCallback) {
        onSuccessCallback(variables);
      }
    },
  });
};
