import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import produce from "immer";
import { brandAPI, LikeBrandResponse, LikeStoreItem, LikeStoreList } from "@amondz/apis-v1";
import { brandApiV2 } from "@amondz/apis-v2";
import { brandQuery } from "./queries";

const likeStoreListFetcher = async (pageNumber: number, pageSize: number): Promise<LikeStoreList> => {
  const data: LikeStoreList = await brandApiV2.likeStoreList({ pageNumber, pageSize });
  return {
    ...data,
    likeStores: produce(data.likeStores, (draft) => {
      draft?.forEach((item) => {
        item.likeFlag = true;
        return item;
      });
    }),
  };
};

export const useLikeBrandList = (
  pageNumber: number,
  pageSize: number,
  option?: {
    onSuccess?: (data: LikeStoreList) => void;
    onLikeSuccess?: (res: LikeBrandResponse, store: LikeStoreItem) => void;
  },
): UseQueryResult<LikeStoreList, Error> & { likeStore: (data: LikeStoreItem) => void; mutateLoading: boolean } => {
  const queryClient = useQueryClient();
  const result = useQuery<LikeStoreList, Error>({
    queryKey: brandQuery.favorites({ pageNumber, pageSize }).queryKey,
    queryFn: () => likeStoreListFetcher(pageNumber, pageSize),
    ...option,
    cacheTime: 0,
  });

  const { mutate, isLoading: mutateLoading } = useMutation({
    mutationFn: (store: LikeStoreItem) => brandAPI.likeBrand({ storeId: store.id }),
    onMutate: async (store: LikeStoreItem) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: brandQuery.favorites({ pageNumber, pageSize }).queryKey });

      // Snapshot the previous value
      const previousData: LikeStoreList | undefined = queryClient.getQueryData(
        brandQuery.favorites({ pageNumber, pageSize }).queryKey,
      );

      if (previousData) {
        // Optimistically update to the new value
        queryClient.setQueryData(
          brandQuery.favorites({ pageNumber, pageSize }).queryKey,
          produce(previousData, (draft) => {
            const target = draft.likeStores.find((item) => item.id === store.id);
            if (!target) return;
            target.likeFlag = !target.likeFlag;
          }),
        );
      }

      // Return a context
      return { previousData, store };
    },
    onSuccess: option?.onLikeSuccess,
    onError: (err, targetId, context) => {
      if (!context) return;
      queryClient.setQueryData(brandQuery.favorites({ pageNumber, pageSize }).queryKey, context.previousData);
    },
  });

  return { ...result, likeStore: mutate, mutateLoading };
};
