import * as React from "react";
import { FC } from "react";

import LikeButton from "@components/icon/LikeButton";
import { LikeToggleButtonStyle } from "./style";

// todo: 폴더 구조 정책 정한 후 정리
export const LIKE_BUTTON_TYPE = {
  SMALL: "small",
  MEDIUM: "medium",
  BIG: "big",
} as const;
const LIKE_BUTTON_SIZE = {
  [LIKE_BUTTON_TYPE.SMALL]: 16,
  [LIKE_BUTTON_TYPE.MEDIUM]: 20,
  [LIKE_BUTTON_TYPE.BIG]: 24,
} as const;
interface ILikeToggleButtonProps {
  className?: string;
  type?: typeof LIKE_BUTTON_TYPE[keyof typeof LIKE_BUTTON_TYPE];
  status: boolean;
  onClickLike?: any;
}

const LikeToggleButton: FC<ILikeToggleButtonProps> = ({ className, type = "small", status, onClickLike }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!onClickLike) return;
    e.preventDefault();
    e.stopPropagation();

    onClickLike();
  };
  return (
    <LikeToggleButtonStyle className={className} onClick={handleClick}>
      <LikeButton width={LIKE_BUTTON_SIZE[type]} height={LIKE_BUTTON_SIZE[type]} status={status} />
    </LikeToggleButtonStyle>
  );
};

export default LikeToggleButton;
