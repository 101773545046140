import * as React from "react";
import { ReactNode, useRef, useState } from "react";

import Category from "./Category";
import { GNB_MENU_ID_TYPE } from "../type";
import { LNB_ANIMATION_SPEED, LNB_POSITION_TOP } from "../constants";
import { LNBStyle } from "./style";

export interface ILNBProps {
  className?: string;
  id: GNB_MENU_ID_TYPE;
  isVisible: boolean;
  onMouseEnter: (id: GNB_MENU_ID_TYPE) => void;
  onMouseLeave: () => void;
  children: ReactNode;
}

const LNB = ({ className, id, isVisible, onMouseEnter, onMouseLeave, children }: ILNBProps): JSX.Element | null => {
  const [top, setTop] = useState(LNB_POSITION_TOP.hide);
  const [prevIsVisible, setPrevIsVisible] = useState(isVisible);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  /**
   * LNB 노출 시 fade-in, fade-out 효과를 위해
   * isVisible === false 일 경우, 컴포넌트를 제거하지 않고 Header 상단으로 숨김
   * isVisible === true 일 경우 Header 하단에 위치
   * isVisible === false 일 경우 Header 상단에 숨김
   */
  if (isVisible !== prevIsVisible) {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    setPrevIsVisible(isVisible);
    if (isVisible) {
      setTop(LNB_POSITION_TOP.show);
    } else {
      // fade-out 효과를 위해 transition: opacity 시간이 지난 후 top 위치 조정
      timeoutId.current = setTimeout(() => {
        setTop(LNB_POSITION_TOP.hide);
      }, LNB_ANIMATION_SPEED);
    }
  }

  return (
    <LNBStyle
      className={className}
      onMouseEnter={() => onMouseEnter(id)}
      onMouseLeave={onMouseLeave}
      style={{ opacity: isVisible ? 1 : 0, top: `${top}px` }}
    >
      {children}
    </LNBStyle>
  );
};

LNB.Category = Category;
export default LNB;
