import styled from "@styles/themedComponents";
import { Divider } from "@amondz/design-system";

export const SectionStyle = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TitleStyle = styled.div`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 27px;
  color: ${(props) => props.theme.color.amondz_black};
`;

export const SectionItemStyle = styled.div`
  letter-spacing: -0.5px;
`;

export const LabelStyle = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.5px;
`;

export const ContentsStyle = styled.p`
  font-size: 13px;
  color: ${(props) => props.theme.color.temp_gray_03};
  line-height: 22px;
  white-space: pre-wrap;
  letter-spacing: -0.5px;
  overflow-wrap: break-word;
`;

export const SubItemWrapStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SubLabelStyle = styled(LabelStyle)`
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 3px;
  letter-spacing: -0.5px;
`;

export const SubContentsStyle = styled(ContentsStyle)`
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.5px;
  color: ${(props) => props.theme.color.temp_gray_03};
  line-height: 22px;
  white-space: pre-wrap;
  margin-left: 8px;
  overflow-wrap: break-word;

  span {
    text-decoration: underline;
  }
`;

export const DividerStyle = styled(Divider)`
  margin: 32px 0;
`;
