import * as React from "react";
import { ReactNode, useCallback } from "react";
import Link from "next/link";

import Row from "@components/common/Row";
import { numberWithCommas } from "@lib/utility/common";
import { PRODUCT_STATUS } from "@constants/enum/productEnum";
import { GIFT_FILTER, GIFT_FILTER_TYPE } from "@constants/service/common/common";
import { PRODUCT_DETAIL_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import TodayDeliveryBadge from "@components/icon/TodayDeliveryBadge";
import {
  BrandNameStyle,
  CardStyle,
  ProductStyle,
  PriceStyle,
  LikeButtonStyle,
  LikeCountStyle,
  CardImageAreaStyle,
  CardInfoAreaStyle,
  SalesPriceStyle,
  DiscountRateStyle,
  TodayBadgeStyle,
  GiftBadgeStyle,
} from "./style";

interface ICardProps {
  className?: string;
  /** 제품인식 index id */
  id: number;
  /**
   * 제품 이미지 컴포넌트
   * 1. Img
   * 2. Slider
   */
  image: ReactNode;
  /** 제품 이름 */
  productName: string;
  /** 브랜드 이름 */
  brandName?: string;
  /** 제품 상태 */
  status?: PRODUCT_STATUS.NOW_ON_SALE | PRODUCT_STATUS.SOLD_OUT;
  /** 실제 판매 가격 */
  salesPrice?: number;
  /** 할인율 */
  discountRate?: number;
  /** 좋아요 갯수 */
  likeCount?: number;
  /** 좋아요 여부 */
  likeFlag?: number | boolean;
  /** 오늘출발 여부 */
  isToday?: boolean;
  /** 선물하기 여부 */
  isGift?: GIFT_FILTER_TYPE;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  /** 좋아요 이벤트 */
  onClickLike?: (id: number) => void;
}

function VerticalCard(props: ICardProps): React.JSX.Element {
  const {
    className,
    id,
    image,
    productName,
    brandName,
    status,
    salesPrice,
    discountRate,
    likeCount,
    likeFlag,
    onClickLike,
    isToday,
    isGift,
    onClick,
  } = props;

  const handleClickLike = (): void => {
    if (!onClickLike || !id) return;
    onClickLike(id);
  };

  const handleClickCard = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    if (!onClick) return;
    onClick(e);
  };

  const drawCard = useCallback(
    () => (
      <CardStyle className={className}>
        <CardImageAreaStyle>{image}</CardImageAreaStyle>
        <CardInfoAreaStyle>
          {brandName && (
            <Row marginBottom={2}>
              <BrandNameStyle isLike={likeFlag !== undefined}>{brandName}</BrandNameStyle>
            </Row>
          )}
          <Row>
            <ProductStyle isLike={likeFlag !== undefined}>{productName}</ProductStyle>
          </Row>
          {salesPrice && (
            <Row marginTop={16}>
              <PriceStyle>
                {status === PRODUCT_STATUS.NOW_ON_SALE && discountRate && (
                  <DiscountRateStyle>{discountRate}</DiscountRateStyle>
                )}
                <SalesPriceStyle status={status}>
                  <span>{numberWithCommas(salesPrice)}</span>
                </SalesPriceStyle>
              </PriceStyle>
              {isToday && (
                <TodayBadgeStyle>
                  <TodayDeliveryBadge isActive={status !== PRODUCT_STATUS.SOLD_OUT} />
                </TodayBadgeStyle>
              )}
            </Row>
          )}
          {!!likeCount && (
            <Row marginTop={4}>
              <LikeCountStyle>{numberWithCommas(likeCount)}</LikeCountStyle>
            </Row>
          )}
          {likeFlag !== undefined && <LikeButtonStyle status={!!likeFlag} onClickLike={handleClickLike} />}
          {isGift === GIFT_FILTER.GIFT && (
            <Row marginTop={8}>
              <GiftBadgeStyle>선물하기</GiftBadgeStyle>
            </Row>
          )}
        </CardInfoAreaStyle>
      </CardStyle>
    ),
    [className, image, productName, brandName, status, salesPrice, discountRate, likeCount, likeFlag, isToday, isGift],
  );

  return (
    <Link
      href={`${PRODUCT_DETAIL_PAGE_URL_PATH}/[id]`}
      as={`${PRODUCT_DETAIL_PAGE_URL_PATH}/${id}`}
      onClick={handleClickCard}
      data-testid="VerticalCard"
    >
      {drawCard()}
    </Link>
  );
}
export default VerticalCard;
