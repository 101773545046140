import { productApiV2, ProductReviewListRequestType } from "@amondz/apis-v2";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { productQueryKeys } from "./keys";

export const useProductReviews = (payload: ProductReviewListRequestType) => {
  const queryClient = useQueryClient();
  return useQuery(
    productQueryKeys.productReviews(payload),
    () => productApiV2.getProductReviewList(payload),
    {
      enabled: !!payload.productId,
    }
  );
};
