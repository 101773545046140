import React from "react";
import { IBaseIconProps } from "@amondz/types";

const AmondzJapanLogo = ({ className, width = 82, height = 12, fill }: IBaseIconProps) => {
  return (
    <svg
      className={className}
      role="img"
      aria-label="amondz-japan-logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 82 12"
      fill={fill}
    >
      <defs>
        <path id="prbkqjfeea" d="M0 0h3.991v5.012H0z" />
        <path id="omqlkl2xmc" d="M0 0h9.018v9.02H0z" />
        <path id="aknjf0a9ne" d="M0 0h8.82v8.754H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="m66.61 4.126-.749 2.28h1.5l-.75-2.28zm2.466 4.169H67.99l-.353-1.054h-2.053l-.353 1.054h-1.086l1.896-5.012h1.14l1.895 5.012zM72.073 4.863c0-.26-.097-.452-.289-.575-.192-.123-.453-.185-.783-.185h-.508v1.588h.455c.35 0 .625-.068.825-.203.2-.136.3-.344.3-.625m1.049 0c0 .276-.057.517-.172.723a1.522 1.522 0 0 1-.473.515c-.2.138-.436.241-.708.309-.272.067-.764.101-1.074.101h-.202v1.784h-1.05V3.283h1.565c.3 0 .579.031.836.094s.481.16.67.29c.19.13.34.294.447.493.107.198.16.432.16.703M75.176 4.126l-.75 2.28h1.5l-.75-2.28zm2.466 4.169h-1.087l-.353-1.054H74.15l-.353 1.054H72.71l1.896-5.012h1.14l1.896 5.012z"
          fill="#000"
        />
        <g transform="translate(78.009 3.283)">
          <mask id="3gfzoi9dkb" fill="#fff">
            <use xlinkHref="#prbkqjfeea" />
          </mask>
          <path
            fill="#000"
            mask="url(#3gfzoi9dkb)"
            d="M3.068 5.012.924 1.731v3.281H0V0h.924l2.144 3.281V0h.923v5.012z"
          />
        </g>
        <path
          d="M61.165 9.087h-.417V8.1c1.632 0 1.848-.786 1.848-2.085V3.283h1.033v2.973c0 1.361-.574 2.83-2.464 2.83M59.115 11.622h-6.596v-1.195l4.173-5.625H52.69v-1.69h6.254v1.196l-4.071 5.625h4.242zM46.37 3.113c1.292 0 2.27.532 2.9 1.398V0h1.792v11.65H49.27v-1.197c-.63.882-1.609 1.414-2.9 1.414-2.256 0-4.13-1.915-4.13-4.378 0-2.48 1.874-4.376 4.13-4.376m2.9 4.376c0-1.547-1.127-2.663-2.62-2.663-1.492 0-2.619 1.116-2.619 2.663 0 1.549 1.127 2.664 2.62 2.664 1.492 0 2.619-1.115 2.619-2.664M41.239 6.541v5.11h-1.791V6.722c0-1.264-.73-1.93-1.857-1.93-1.176 0-2.105.7-2.105 2.398v4.46h-1.79V3.327h1.79v1.065c.547-.864 1.443-1.28 2.57-1.28 1.856 0 3.183 1.265 3.183 3.428"
          fill="#000"
        />
        <g transform="translate(23.747 2.98)">
          <mask id="vq6hfa0e7d" fill="#fff">
            <use xlinkHref="#omqlkl2xmc" />
          </mask>
          <path
            d="M7.347 3.946c.008-.77-.275-1.436-.797-1.878a2.233 2.233 0 0 0-1.462-.51c-.923 0-1.877.48-2.552 1.29C1.411 4.194 1.38 6.036 2.47 6.952c.397.334.902.511 1.461.511.924 0 1.878-.482 2.552-1.29.549-.655.856-1.447.865-2.227M4.508 0c2.53 0 4.51 1.98 4.51 4.51 0 2.529-1.98 4.51-4.51 4.51C1.981 9.02 0 7.04 0 4.51 0 1.98 1.98 0 4.508 0"
            fill="#000"
            mask="url(#vq6hfa0e7d)"
          />
        </g>
        <path
          d="M22.63 6.491v5.16h-1.79V6.606c0-1.164-.63-1.814-1.609-1.814-1.06 0-1.806.683-1.806 2.197v4.66h-1.791V6.607c0-1.164-.58-1.814-1.558-1.814-1.011 0-1.841.683-1.841 2.197v4.66h-1.79V3.328h1.79v1c.531-.817 1.344-1.215 2.37-1.215 1.079 0 1.874.481 2.356 1.315.547-.866 1.425-1.315 2.536-1.315 1.872 0 3.133 1.298 3.133 3.378"
          fill="#000"
        />
        <g transform="translate(0 3.113)">
          <mask id="gist7lxnof" fill="#fff">
            <use xlinkHref="#aknjf0a9ne" />
          </mask>
          <path
            d="M7.03 4.376c0-1.547-1.127-2.663-2.62-2.663-1.492 0-2.62 1.116-2.62 2.663 0 1.549 1.128 2.664 2.62 2.664 1.493 0 2.62-1.115 2.62-2.664zM8.82.215v8.322H7.03V7.34c-.63.882-1.609 1.414-2.901 1.414-2.255 0-4.13-1.915-4.13-4.378C0 1.896 1.875 0 4.13 0c1.292 0 2.27.532 2.9 1.398V.215H8.82z"
            fill="#000"
            mask="url(#gist7lxnof)"
          />
        </g>
      </g>
    </svg>
  );
};

export default AmondzJapanLogo;
