import styled from "@styles/themedComponents";

export const BottomSideFooterStyle = styled.article`
  .company-info,
  .terms-and-condition-wrap .terms-and-condition-content {
    max-width: 1920px;
    margin: 0 auto;
  }

  .terms-and-condition-wrap {
    height: 50px;
    border-top: 1px solid ${(props) => props.theme.color.gray_07};
    border-bottom: 1px solid ${(props) => props.theme.color.gray_07};

    .terms-and-condition-content {
      padding: 14px 64px;
      overflow: hidden;

      .amondz-log {
        width: 67px;
        height: 20px;
        vertical-align: top;
      }

      .term-list {
        float: right;

        li {
          &:not(:last-child) {
            margin-right: 27px;
          }

          display: inline-block;
          opacity: 0.7;
          font-size: 11px;
          line-height: 17px;
        }
      }
    }
  }

  .company-info {
    padding: 24px 64px 48px;

    li {
      &:not(:last-child) {
        margin-bottom: 3px;
      }

      font-size: 11px;
      line-height: 17px;
      font-weight: 300;

      .business-license-information {
        margin-left: 8px;
        font-weight: 500;
        text-decoration-line: underline !important;
      }

      span {
        opacity: 0.7;
      }

      span + span {
        margin-left: 8px;
      }
    }
  }
`;
