import React, { FC, ReactNode } from "react";

import { SearchModalInfoBaseSectionStyle, SearchModalInfoBaseSectionTitleStyle } from "./style";

interface ISearchModalInfoBaseSectionProps {
  className?: string;
  // 타이틀 이름
  title: string;
  // 타이틀 아래 margin-bottom 값
  titleMarginBottomValue?: number;
  // T/M 화면에서 타이틀 아래 margin-bottom 값
  mobileTitleMarginBottomValue?: number;
  // base section border-bottom 여부
  sectionBorderBottom?: boolean;
  children: ReactNode;
}

const SearchModalInfoBaseSection: FC<ISearchModalInfoBaseSectionProps> = (props) => {
  const {
    className,
    title,
    titleMarginBottomValue = 16,
    mobileTitleMarginBottomValue = 20,
    sectionBorderBottom = false,
    children,
  } = props;

  return (
    <SearchModalInfoBaseSectionStyle className={className} sectionBorderBottom={sectionBorderBottom}>
      <SearchModalInfoBaseSectionTitleStyle
        titleMarginBottomValue={titleMarginBottomValue}
        mobileTitleMarginBottomValue={mobileTitleMarginBottomValue}
      >
        {title}
      </SearchModalInfoBaseSectionTitleStyle>
      {children}
    </SearchModalInfoBaseSectionStyle>
  );
};

export default SearchModalInfoBaseSection;
