import * as React from "react";
import { FC } from "react";
import { AmondzJapanLogo, AmondzMagazineLogo, AmondzUSLogo } from "@amondz/components";
import Icon from "@components/common/Icon/Icon";
import {
  AMONDZ_MAGAZINE_URL,
  BEJEWEL_COMPANY_SITE_URL,
  BYAMONDZ_JAPAN_URL,
  BYAMONDZ_US_URL,
  FACEBOOK_AMONDZ_CHANNEL_URL,
  INSTAGRAM_AMONDZ_CHANNEL_URL,
  KAKAO_AMONDZ_CHANNEL_URL,
  ONE_SERVICE_URL,
  YOUTUBE_AMONDZ_CHANNEL_URL,
} from "@constants/url/externalUrlConstants";
import {
  FOOTER_SOCIAL_FACEBOOK_BTN_IMAGE_PATH,
  FOOTER_SOCIAL_INSTA_BTN_IMAGE_PATH,
  FOOTER_SOCIAL_KAKAO_BTN_IMAGE_PATH,
  FOOTER_SOCIAL_YOUTUBE_BTN_IMAGE_PATH,
} from "@constants/static/images";
import { ChannelWrapperStyle, NoticeWrapperStyle, TopSideFooterStyle } from "./style";

// 회사소개, 입점신청, 아몬즈 소셜 채널, 법적고지 등을 담고 있는 상단 footer
const TopSideFooter: FC = () => (
  <TopSideFooterStyle>
    <div className="section-wrap">
      <div className="section notice-wrapper">
        <NoticeWrapperStyle>
          <div className="section section-title">
            <a href={BEJEWEL_COMPANY_SITE_URL} target="_blank" rel="noreferrer">
              회사소개
            </a>
          </div>
          <ul className="section section-menu-list">
            <li className="section-title">
              <a href={ONE_SERVICE_URL} target="_blank" rel="noreferrer">
                입점신청
              </a>
            </li>
            <li className="section-title">
              <a href="https://forms.gle/Yn8X52yg9W2joNCUA" target="_blank" rel="noreferrer">
                제휴문의
              </a>
            </li>
          </ul>
        </NoticeWrapperStyle>
        <div className="section legal-notice">
          <div className="section-title">법적 고지</div>
          <ul className="section-list">
            <li>
              (주) 비주얼은 결제정보의 중개서비스 또는 통신판매중개시스템의 제공자로서, 통신판매의 당사자가 아니며,
            </li>
            <li>상품의 주문, 배송 및 환불 등과 관련한 의무와 책임이 각 판매자에게 1차로 있습니다.</li>
            <li>단, (주) 비주얼 또한 상품의 주문, 배송 및 환불등과 관련한 의무와 책임을 가지고있습니다.</li>
          </ul>
        </div>
      </div>
      <ChannelWrapperStyle>
        <div className="section logo-wrapper">
          <div className="section global-btn">
            <div className="section-title">글로벌</div>
            <ul className="section-list">
              <li>
                <a href={BYAMONDZ_JAPAN_URL} target="_blank" rel="noreferrer">
                  <AmondzJapanLogo width={82} height={12} />
                </a>
              </li>
              <li>
                <a href={BYAMONDZ_US_URL} target="_blank" rel="noreferrer">
                  <AmondzUSLogo width={70} height={12} />
                </a>
              </li>
            </ul>
          </div>
          <div className="section magazine-btn">
            <div className="section-title">매거진</div>
            <ul className="section-list">
              <li>
                <a href={AMONDZ_MAGAZINE_URL} target="_blank" rel="noreferrer">
                  <AmondzMagazineLogo width={92} height={12} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="section social-btn">
          <div className="section-title">아몬즈를 다양한 채널에서 만나보세요.</div>
          <ul className="section-list">
            <li>
              <a href={INSTAGRAM_AMONDZ_CHANNEL_URL} target="_blank" rel="noreferrer">
                <Icon src={FOOTER_SOCIAL_INSTA_BTN_IMAGE_PATH} />
              </a>
            </li>
            <li>
              <a href={YOUTUBE_AMONDZ_CHANNEL_URL} target="_blank" rel="noreferrer">
                <Icon src={FOOTER_SOCIAL_YOUTUBE_BTN_IMAGE_PATH} />
              </a>
            </li>
            <li>
              <a href={FACEBOOK_AMONDZ_CHANNEL_URL} target="_blank" rel="noreferrer">
                <Icon src={FOOTER_SOCIAL_FACEBOOK_BTN_IMAGE_PATH} />
              </a>
            </li>
            <li>
              <a href={KAKAO_AMONDZ_CHANNEL_URL} target="_blank" rel="noreferrer">
                <Icon src={FOOTER_SOCIAL_KAKAO_BTN_IMAGE_PATH} />
              </a>
            </li>
          </ul>
        </div>
      </ChannelWrapperStyle>
    </div>
  </TopSideFooterStyle>
);

export default TopSideFooter;
