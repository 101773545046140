import { BRAND_LIST_PAGE_URL_PATH, PRODUCT_LIST_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { MAIN_HEADER_HEIGHT_WITHOUT_GNB } from "@constants/service/base/header";

export const GNB_MENU_ID = {
  CATEGORY: 0,
  BRAND: 1,
} as const;

// 헤더 메인 메뉴 리스트
export const GNB_MENU = [
  {
    id: GNB_MENU_ID.CATEGORY,
    url: PRODUCT_LIST_PAGE_URL_PATH,
    name: "카테고리",
  },
  {
    id: GNB_MENU_ID.BRAND,
    url: BRAND_LIST_PAGE_URL_PATH,
    name: "브랜드",
  },
];

export const HIDE_LNB_STATE = {
  isVisible: false,
  triggerId: null,
};

export const LNB_POSITION_TOP = {
  show: MAIN_HEADER_HEIGHT_WITHOUT_GNB + 1,
  hide: -1000,
};

export const LNB_ANIMATION_SPEED = 500;
