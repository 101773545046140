import * as React from "react";
import { FC, MouseEvent, MouseEventHandler, memo, ReactNode } from "react";

import { BUTTON_COLOR, BUTTON_SIZE } from "@constants/enum/baseEnums";
import SpinnerIcon from "../spinner/SpinnerIcon/SpinnerIcon";
import { ButtonStyle } from "./style";

export interface IButtonProps {
  className?: string;
  color: BUTTON_COLOR;
  size: BUTTON_SIZE;
  disabled?: boolean;
  style?: any;
  type?: any;
  onClick?: MouseEventHandler;
  isLoading?: boolean;
  text: string | ReactNode;
}

const Button: FC<IButtonProps> = (props: IButtonProps) => {
  const { className, color, size, disabled, style, type, onClick, isLoading = false, text } = props;

  const handleClick = (e: MouseEvent) => {
    if (!onClick) return;
    if (!disabled) {
      onClick(e);
    }
  };

  return (
    <ButtonStyle
      className={className}
      color={color}
      size={size}
      style={style}
      type={type}
      disabled={disabled}
      isLoading={isLoading}
      onClick={handleClick}
    >
      {isLoading ? <SpinnerIcon className="spinner-icon" /> : text}
    </ButtonStyle>
  );
};

export default memo(Button);
