import * as React from "react";
import { ReactNode, useMemo } from "react";

import { CommonListStyle, ListStyle } from "./style";

export interface IListProps {
  className?: string;
  column: number;
  rowGap?: number;
  columnGap?: number;
  pagination?: {
    pageCount: number;
    currentPage: number;
  } | null;
  onChange: (selectedItem: { selected: number }) => void;
  children: ReactNode;
}

function List(props: IListProps) {
  const { className, column, rowGap = 0, columnGap = 0, pagination, onChange, children } = props;
  const calcGridColumns = useMemo(() => `(100% - ${columnGap * (column - 1)}px) / ${column}`, [column, columnGap]);
  return (
    <CommonListStyle className={className} pagination={pagination} onChange={onChange}>
      <ListStyle calcGridColumns={calcGridColumns} column={column} rowGap={rowGap} columnGap={columnGap}>
        {children}
      </ListStyle>
    </CommonListStyle>
  );
}

export default List;
