import * as React from "react";
import { FC, memo } from "react";

import Slider from "@components/common/Slider";
import Product from "@components/common/Product";
import Img from "@components/common/Img/Img";
import { FromType, useProductDetailFromPage } from "@amondz/marketing-service";
import { ContentsStyle, HeaderStyle, SlideListStyle } from "./style";

// todo: 타입 통합 작업 시 수정 예정
export type SlideListProductInfo = {
  id: number;
  name: string;
  discountRate: number;
  salesPrice: number;
  status: number;
  imgUrl: string;
};

interface ISlideListProps {
  className?: string;
  title?: string;
  productList: SlideListProductInfo[];
  slidesPerView?: number;
  slidesPerGroup?: number;
  variableWidth?: boolean;
  pagination?: boolean;
}

/**
 * 스와이프 가능한 상품 리스트
 * @param props
 * @constructor
 */
const SlideList: FC<ISlideListProps> = (props) => {
  const {
    className,
    title,
    productList,
    slidesPerView = 5,
    slidesPerGroup = 5,
    variableWidth = false,
    pagination = true,
  } = props;
  const { setFrom } = useProductDetailFromPage();

  const handleClick = (): void => {
    // 블럭스 추천 상품을 통해 상품 상세 페이지 접근 시 from 정보를 보내줘야함
    setFrom(FromType.PRODUCT_DETAIL_PAGE);
  };

  return (
    <SlideListStyle className={className}>
      {title ? (
        <HeaderStyle>
          <strong>{title}</strong>
        </HeaderStyle>
      ) : null}
      <ContentsStyle>
        <Slider
          className="product-list-slider"
          loop={productList.length > slidesPerGroup}
          pagination={pagination}
          slidesPerView={variableWidth ? "auto" : slidesPerView}
          slidesPerGroup={slidesPerGroup}
          spaceBetween={15}
        >
          {productList.map((item) => (
            <Product.VerticalCard
              key={item.id}
              className="product-list-item"
              id={item.id}
              image={<Img src={item.imgUrl!} />}
              productName={item.name}
              status={item.status}
              salesPrice={item.salesPrice}
              discountRate={item.discountRate}
              onClick={handleClick}
            />
          ))}
        </Slider>
      </ContentsStyle>
    </SlideListStyle>
  );
};

export default memo(SlideList);
