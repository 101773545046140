import styled from "@styles/themedComponents";
import { CustomBarPaginationSliderStyle } from "@components/common/Slider/style";

// productInfo 높이 + pagination 여백 높이
const ProductInfoHeight = 104;
export const SlideListStyle = styled.div`
  .product-list-slider {
    ${CustomBarPaginationSliderStyle};

    .swiper-button-prev,
    .swiper-button-next {
      top: calc((100% - ${ProductInfoHeight}px) / 2);
    }
  }
`;

export const HeaderStyle = styled.div`
  margin-bottom: 16px;

  strong {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 32px;
  }
`;

export const ContentsStyle = styled.div``;
