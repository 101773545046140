export const mainHomeQueryKeys = {
  all: ["home"],
  list: () => [...mainHomeQueryKeys.all],
  tabList: () => [...mainHomeQueryKeys.all, "tab", "list"],
};

export const subHomeQueryKeys = {
  all: ["subHome"],
  list: (homeId: number) => [...subHomeQueryKeys.all, homeId],
};
