import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router, { useRouter } from "next/router";
import { isNil } from "lodash";

import { setLastSearchKeyword, setSearchKeyword } from "@store/modules/search";
import { RootStateType } from "@store/modules";
import {
  eventSearchCount,
  integrationSearchCount,
  productSearchCount,
  reviewSearchCount,
  searchModalStateType,
  searchModalVisible,
  setRecentSearchKeywordList,
  storeSearchCount,
} from "@store/modules/search";
import Icon from "@components/common/Icon";
import { PRODUCT_PRICE_FILTER_DELETE_BTN_IMAGE_PATH } from "@constants/static/images";
import { RECENT_SEARCH_WORD_MAX_LENGTH } from "@constants/service/search/search";
import { SEARCH_RESULT_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { RecentSearchWord, RecentSearchWordListStyle } from "./style";

interface IRecentSearchWordListProps {
  wordList: string[];
}

const RecentSearchWordList: FC<IRecentSearchWordListProps> = (props) => {
  const { wordList } = props;
  const { lastSearchKeyword } = useSelector<RootStateType, searchModalStateType>(
    (state: RootStateType) => state.search.searchModalState,
  );

  const router = useRouter();
  const dispatch = useDispatch();

  const onClickRecentSearchWordButton = useCallback(
    (word: string) => () => {
      // 이전 검색 결과와 다른 경우 검색 결과 수 초기화
      if (word !== lastSearchKeyword) {
        dispatch(integrationSearchCount({ count: 0 }));
        dispatch(productSearchCount({ count: 0 }));
        dispatch(storeSearchCount({ count: 0 }));
        dispatch(reviewSearchCount({ count: 0 }));
        dispatch(eventSearchCount({ count: 0 }));

        // 재정의한 최근 검색어 리스트
        let returnSearchKeywordList: string[] = [];
        // 중복 단어 인 경우 삭제하고 리스트 맨 앞에 삽입
        if (wordList.includes(word)) {
          const filterList = wordList.filter((keyword) => keyword !== word);
          returnSearchKeywordList = [word, ...filterList];
          dispatch(setRecentSearchKeywordList({ recentSearchKeywordList: [word, ...filterList] }));
        } else {
          // 최근 검색어의 길이가 최대 길이(10)을 초과할 경우 제일 오래된 기록을 검색 기록을 제거
          if (wordList.length >= RECENT_SEARCH_WORD_MAX_LENGTH) {
            returnSearchKeywordList = [word, ...wordList.slice(0, RECENT_SEARCH_WORD_MAX_LENGTH - 1)];
            dispatch(
              setRecentSearchKeywordList({
                recentSearchKeywordList: [word, ...wordList.slice(0, RECENT_SEARCH_WORD_MAX_LENGTH - 1)],
              }),
            );
          } else {
            returnSearchKeywordList = [word, ...wordList];
            dispatch(
              setRecentSearchKeywordList({
                recentSearchKeywordList: [word, ...wordList],
              }),
            );
          }
        }

        if (!isNil(localStorage)) {
          localStorage.setItem("searchHistory", JSON.stringify(returnSearchKeywordList));
        }
      }

      // 검색 페이지 내부에서는 history stack 쌓지 않음
      if (router.pathname.includes(SEARCH_RESULT_PAGE_URL_PATH)) {
        Router.replace(`${SEARCH_RESULT_PAGE_URL_PATH}?keyword=${encodeURIComponent(word)}`, undefined);
      } else {
        Router.push(`${SEARCH_RESULT_PAGE_URL_PATH}?keyword=${encodeURIComponent(word)}`, undefined);
      }
      dispatch(setSearchKeyword({ searchKeyword: word }));
      dispatch(setLastSearchKeyword({ lastSearchKeyword: word }));
      dispatch(searchModalVisible({ isSearchModalVisible: false }));
    },
    [wordList],
  );

  return (
    <RecentSearchWordListStyle>
      {wordList.map(
        (word, index) =>
          index < 5 && (
            <li key={word + index} className="recent-search-word-list">
              <a onClick={onClickRecentSearchWordButton(word)}>
                <RecentSearchWord>{word}</RecentSearchWord>
                <Icon
                  className="delete-icon"
                  src={PRODUCT_PRICE_FILTER_DELETE_BTN_IMAGE_PATH}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isNil(localStorage)) {
                      const filterHistoryData = JSON.parse(localStorage.getItem("searchHistory")!).filter(
                        (data: string) => data !== word,
                      );
                      dispatch(setRecentSearchKeywordList({ recentSearchKeywordList: filterHistoryData }));
                      localStorage.setItem("searchHistory", JSON.stringify(filterHistoryData));
                    }
                  }}
                />
              </a>
            </li>
          ),
      )}
    </RecentSearchWordListStyle>
  );
};

export default RecentSearchWordList;
