import * as React from "react";
import { FC, ReactNode } from "react";
import { RowStyle } from "@components/common/Row/style";

export interface IRowProps {
  marginTop?: number;
  marginBottom?: number;
  direction?: "row" | "column";
  children?: ReactNode;
}
const Row: FC<IRowProps> = (props) => {
  const { marginTop = 0, marginBottom = 0, direction = "row", children } = props;
  return (
    <RowStyle marginTop={marginTop} marginBottom={marginBottom} direction={direction}>
      {children}
    </RowStyle>
  );
};
export default Row;
