import * as React from "react";
import { ReactNode } from "react";

import List from "./List";
import InfiniteList from "./InfiniteList";
import VerticalCard from "./VerticalCard";
import HorizontalCard from "./HorizontalCard";
import SlideList from "./SlideList";
import { ProductStyle } from "./style";

interface IProductProps {
  children: ReactNode;
}

function Product({ children }: IProductProps) {
  return <ProductStyle>{children}</ProductStyle>;
}

Product.List = List;
Product.InfiniteList = InfiniteList;
Product.VerticalCard = VerticalCard;
Product.HorizontalCard = HorizontalCard;
Product.SlideList = SlideList;

export default Product;
