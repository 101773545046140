import styled, { css } from "@styles/themedComponents";

const selectedStyle = css`
  &:after {
    position: absolute;
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background-color: black;
    bottom: -1px;
  }
`;
export const GNBStyled = styled.ul`
  display: flex;
  height: 100%;
  gap: 32px;

  li {
    position: relative;
    display: flex;
    align-items: center;

    &.selected {
      ${selectedStyle}
    }

    &:has(a:hover) {
      ${selectedStyle}
    }

    a {
      font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
      font-size: 15px;
      font-weight: bold;
      line-height: 22px;
      letter-spacing: -0.5px;
      color: ${(props) => props.theme.color.amondz_black};
    }
  }
`;
