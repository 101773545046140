import * as React from "react";
import Link from "next/link";
import { Avatar } from "@amondz/design-system";

import { PRODUCT_LIST_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { EmphasisCategoryType, NormalCategoryType } from "../../useCategoryTreeViewModel";
import { CategoryStyle, NormalCategoryListStyle, EmphasisCategoryListStyle } from "./style";

export interface ICategoryProps {
  className?: string;
  normalCategoryList: NormalCategoryType[];
  emphasisCategoryList: EmphasisCategoryType[];
}

const Category = ({ className, normalCategoryList, emphasisCategoryList }: ICategoryProps): JSX.Element | null => {
  return (
    <CategoryStyle className={className}>
      <NormalCategoryListStyle>
        <ul>
          {normalCategoryList.map((item) => (
            <li key={item.id}>
              <Link
                href={`${PRODUCT_LIST_PAGE_URL_PATH}?category=${item.id}&sortType=${item.sortType}`}
                className="normal-category"
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </NormalCategoryListStyle>
      {emphasisCategoryList.length > 0 && (
        <EmphasisCategoryListStyle>
          <ul>
            {emphasisCategoryList.map((item) => (
              <li key={item.id}>
                <Link
                  href={`${PRODUCT_LIST_PAGE_URL_PATH}?category=${item.id}&sortType=${item.sortType}`}
                  className="emphasis-category"
                >
                  <Avatar className="emphasis-category-img" alt={item.name} src={item.src} />
                  <p className="emphasis-category-name">{item.name}</p>
                </Link>
              </li>
            ))}
          </ul>
        </EmphasisCategoryListStyle>
      )}
    </CategoryStyle>
  );
};

export default Category;
