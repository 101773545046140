import { Address } from "@amondz/types";

export const formatAddressByType = (addresses: Address[], desiredType: "brand" | "origin" | "return"): string => {
  const foundAddress = addresses.find((address) => address.type === desiredType);

  if (!foundAddress) {
    return "-";
  }

  return `(${foundAddress.postCode}) ${foundAddress.mainAddress} ${foundAddress.detailAddress}`;
};
