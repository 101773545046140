import { toNumber, toString } from "lodash";

import searchAPI from "@services/apis/searchAPI";
import { TODAY_DELIVERY_FILTER_TYPE } from "@constants/service/common/common";
import { PRODUCT_CATEGORY_TREE_EXPOSURE_TYPE_LIST } from "@constants/service/product/product";
import {
  searchBrandListRequestType,
  searchBrandListType,
  searchProductListRequestType,
  searchProductListType,
  searchReviewListRequestType,
  searchReviewListType,
  searchEventListType,
  searchEventListRequestType,
  searchIntegrationListRequestType,
  searchIntegrationListType,
  searchBeforeAutoCompleteListType,
  searchBeforeIntegrationListType,
} from "@swr/types/searchTypes";
import { productCategoryDataType } from "@swr/types/brandTypes";

// 검색 전 통합 검색 리스트 Fetcher
export const searchBeforeIntegrationListFetcher = (): Promise<searchBeforeIntegrationListType> => {
  return searchAPI.searchBeforeIntegrationList();
};

// 검색 중 자동완성 리스트 Fetcher
export const searchBeforeAutoCompleteListFetcher = (keyword: string): Promise<searchBeforeAutoCompleteListType> => {
  return searchAPI.searchBeforeAutoCompleteList({
    keyword,
  });
};

// 통합 검색 리스트 Fetcher
export const searchIntegrationListFetcher = (
  payload: searchIntegrationListRequestType,
): Promise<searchIntegrationListType> => {
  const { keyword } = payload;

  return searchAPI.integrationList({
    keyword,
  });
};

// 상품 검색 리스트 Fetcher
export const searchProductListFetcher = (payload: searchProductListRequestType): Promise<searchProductListType> => {
  const {
    keyword,
    sortType,
    isTodayDelivery,
    startIndex,
    exceptAllCount,
    exceptCategoryTree,
    categoryIdCSV,
    storeIdCSV,
    baseIdCSV,
    shapeIdCSV,
    gemstoneIdCSV,
    postEarringIdCSV,
    chainShapeIdCSV,
    colorIdCSV,
    priceRangeCSV,
    discountRate,
  } = payload;

  const result = searchAPI
    .productList({
      keyword,
      sortType: toNumber(sortType),
      isTodayDelivery: toNumber(isTodayDelivery) as TODAY_DELIVERY_FILTER_TYPE,
      startIndex: toNumber(startIndex),
      exceptAllCount: toString(exceptAllCount) === "true",
      exceptCategoryTree: toString(exceptCategoryTree) === "true",
      categoryIdCSV,
      storeIdCSV,
      baseIdCSV,
      shapeIdCSV,
      gemstoneIdCSV,
      postEarringIdCSV,
      chainShapeIdCSV,
      colorIdCSV,
      priceRangeCSV,
      discountRate: toNumber(discountRate),
    })
    .then((res) => {
      return {
        ...res,
        status: res.status,
        data: {
          searchResult: { ...res.data.searchResult },
          recommend: { ...res.data.recommend },
          categoryTree: [
            ...res.data.categoryTree.normal.filter(
              (category: productCategoryDataType) =>
                category.exposureType === PRODUCT_CATEGORY_TREE_EXPOSURE_TYPE_LIST.COMMON,
            ),
            ...res.data.categoryTree.emphasis.filter(
              (category: productCategoryDataType) =>
                category.exposureType === PRODUCT_CATEGORY_TREE_EXPOSURE_TYPE_LIST.COMMON,
            ),
          ],
        },
      };
    });
  return result;
};

// 브랜드 검색 리스트 Fetcher
export const searchBrandListFetcher = (payload: searchBrandListRequestType): Promise<searchBrandListType> => {
  const { keyword, startIndex } = payload;

  return searchAPI.brandList({
    keyword,
    startIndex: toNumber(startIndex),
  });
};

// 상품 후기 검색 리스트 Fetcher
export const searchReviewListFetcher = (payload: searchReviewListRequestType): Promise<searchReviewListType> => {
  const { keyword, startIndex } = payload;

  return searchAPI.reviewList({
    keyword,
    startIndex: toNumber(startIndex),
  });
};

// 기획전 검색 리스트 Fetcher
export const searchEventListFetcher = (payload: searchEventListRequestType): Promise<searchEventListType> => {
  const { keyword, startIndex } = payload;

  return searchAPI.eventList({
    keyword,
    startIndex: toNumber(startIndex),
  });
};
