import useSWR, { Fetcher, SWRConfiguration, SWRResponse } from "swr";

import { searchBeforeIntegrationListKey } from "@swr/keys/searchKeys";
import { searchBeforeIntegrationListType } from "@swr/types/searchTypes";
import { searchBeforeIntegrationListFetcher } from "@swr/fetchers/search/searchFetchers";

function useSearchBeforeIntegrationList(
  option?: Partial<
    SWRConfiguration<searchBeforeIntegrationListType, Error, Fetcher<searchBeforeIntegrationListType, string>>
  >,
): SWRResponse<searchBeforeIntegrationListType, Error> {
  return useSWR(searchBeforeIntegrationListKey, searchBeforeIntegrationListFetcher, option);
}

export default useSearchBeforeIntegrationList;
