import { useQuery } from "@tanstack/react-query";
import { eventAPI, GetEventDetailSuccess } from "@amondz/apis-v1";
import { EventDetailResponse, EventDetailType } from "@amondz/types";
import { eventQueryKeys } from "./keys";

const convertEventDetailResponse = (response: GetEventDetailSuccess): EventDetailResponse => {
  let data: EventDetailType | undefined;
  if (response.data) {
    data = {
      ...response.data,
      hasItemFilter: response.data.isItemFilterExpose,
      defaultSortType: response.data.itemDefaultSortType,
    };
  }
  return {
    ...response,
    data,
  };
};

const useEventDetail = (eventId: number) => {
  return useQuery({
    queryKey: eventQueryKeys.eventDetail(eventId),
    queryFn: () =>
      eventAPI.getEventDetail({
        eventId,
      }),
    select: convertEventDetailResponse,
  });
};
export default useEventDetail;
