import styled from "@styles/themedComponents";

interface ISearchModalInfoBaseSectionStyleProps {
  sectionBorderBottom: boolean;
}

interface ISearchModalInfoBaseSectionTitleStyleProps {
  titleMarginBottomValue: number;
  mobileTitleMarginBottomValue: number;
}

export const SearchModalInfoBaseSectionStyle = styled.article<ISearchModalInfoBaseSectionStyleProps>`
  position: relative;
`;

export const SearchModalInfoBaseSectionTitleStyle = styled.header<ISearchModalInfoBaseSectionTitleStyleProps>`
  margin-bottom: ${({ titleMarginBottomValue }) => titleMarginBottomValue}px;
  height: 27px;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.5px;
`;
