// 검색 전 통합 검색 리스트 key
export const searchBeforeIntegrationListKey = "search/ready/main/web/v1";

// 검색 전 자동완성 리스트 key
export const searchBeforeAutoCompleteListKey = "search/typing/web/v1";

// 통합 검색 결과 요청 key
export const integrationListKey = "search/result/main/web/v1";

// 상품 검색 결과 요청 key
export const productListKey = "search/result/product/pagination/web/v1";

// 브랜드 검색 결과 요청 key
export const brandListKey = "search/result/brand/pagination/web/v1";

// 후기 검색 결과 요청 key
export const reviewListKey = "search/result/review/pagination/web/v1";

// 기획전 검색 결과 요청 key
export const eventListKey = "search/result/event/pagination/web/v1";
