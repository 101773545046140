import * as React from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { GNB_MENU } from "../constants";
import { GNB_MENU_ID_TYPE } from "../type";
import { GNBStyled } from "./style";

export interface IGNBProps {
  className?: string;
  categoryUrl: string;
  onMouseEnter: (id: GNB_MENU_ID_TYPE) => void;
  onMouseLeave: () => void;
}

const GNB = ({ className, categoryUrl, onMouseEnter, onMouseLeave }: IGNBProps): JSX.Element => {
  const router = useRouter();

  return (
    <GNBStyled className={className} onMouseLeave={onMouseLeave}>
      {/*Todo: url 에 categoryId 가 없을 경우 상품리스트가 제대로 요청되지 않는 이슈가 있어 categoryTree 결과에 맞춰 url 생성하도록 수정*/}
      {/*Todo: 추후 필터 작업 진행시 상품 리스트 호출 조건을 개선할 필요가 있음*/}
      <li className={router.pathname === GNB_MENU[0].url ? "selected" : ""}>
        <Link href={categoryUrl}>
          <a onMouseEnter={() => onMouseEnter(GNB_MENU[0].id)}>{GNB_MENU[0].name}</a>
        </Link>
      </li>
      <li className={router.pathname === GNB_MENU[1].url ? "selected" : ""}>
        <Link href={GNB_MENU[1].url}>
          <a onMouseEnter={() => onMouseEnter(GNB_MENU[1].id)}>{GNB_MENU[1].name}</a>
        </Link>
      </li>
    </GNBStyled>
  );
};

export default GNB;
