import * as React from "react";
import { FC } from "react";

import TopSideFooter from "@components/base/footer/TopSideFooter";
import BottomSideFooter from "@components/base/footer/BottomSideFooter";
import { FooterStyle } from "./style";

interface IFooterProps {
  /**
   * 회사소개, 입점신청, 글로벌, 아몬즈 채널 정보가 담겨있는 footer 노출여부
   */
  isTopSideFooter?: boolean;
}
const Footer: FC<IFooterProps> = ({ isTopSideFooter = true }) => (
  <FooterStyle>
    {isTopSideFooter && <TopSideFooter />}
    <BottomSideFooter />
  </FooterStyle>
);

export default Footer;
