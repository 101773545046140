import styled from "@amondz/design-system/src/style/themeComponents";
export const RateStyle = styled.div`
  display: flex;
  gap: 2px;
  
  div  {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
