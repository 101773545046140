import * as React from "react";
import { ReactNode, useEffect, useMemo, useRef } from "react";
import { throttle } from "lodash";

import { InfiniteListStyle, SpinnerWrapperStyle } from "./style";
import SpinnerIcon from "@components/common/spinner/SpinnerIcon";

export interface IInfiniteListProps {
  column: number;
  rowGap?: number;
  columnGap?: number;
  // request callback
  onMoreRequest?: () => void;
  // request 할 위치
  moreRequestPosition?: number;
  // request 가능한지 여부
  isPossibleRequest?: boolean;
  // 마지막 페이지 여부
  isMore?: boolean;
  children: ReactNode;
}

function InfiniteList(props: IInfiniteListProps) {
  const {
    column,
    rowGap = 0,
    columnGap = 0,
    onMoreRequest,
    moreRequestPosition = 640,
    isPossibleRequest,
    isMore,
    children,
  } = props;
  const calcGridColumns = useMemo(() => `(100% - ${columnGap * (column - 1)}px) / ${column}`, [column, columnGap]);
  const ListEl = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const onScroll = throttle(() => {
      if (!ListEl.current) return;

      const windowBottom = window.pageYOffset + window.innerHeight;
      const ListBottom = ListEl.current.offsetTop + ListEl.current.offsetHeight;

      if (windowBottom >= ListBottom - moreRequestPosition && isPossibleRequest && isMore && onMoreRequest) {
        onMoreRequest();
      }
    }, 200);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [moreRequestPosition, isPossibleRequest, isMore, onMoreRequest]);

  return (
    <>
      <InfiniteListStyle
        ref={ListEl}
        calcGridColumns={calcGridColumns}
        column={column}
        rowGap={rowGap}
        columnGap={columnGap}
      >
        {children}
      </InfiniteListStyle>
      {isMore && (
        <SpinnerWrapperStyle>
          <SpinnerIcon />
        </SpinnerWrapperStyle>
      )}
    </>
  );
}

export default InfiniteList;
