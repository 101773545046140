import * as React from "react";
import { FC, memo, ReactNode } from "react";
import ReactPaginate from "react-paginate";

import { ARROW_LEFT_OUTLINE_BLACK_IMAGE_PATH, ARROW_RIGHT_OUTLINE_BLACK_IMAGE_PATH } from "@constants/static/images";
import { LIST_PAGE_RANGE_DISPLAYED } from "@constants/service/common/common";
import { DeviceSize } from "@styles/themedComponents";
import LoadingSpinner from "../spinner/LoadingSpinner";
import Icon from "../Icon";
import { ListStyle } from "./style";

export interface ListProps {
  className?: string;
  onChange: (selectedItem: { selected: number }) => void;
  pagination?: {
    pageCount: number;
    currentPage: number;
  } | null;
  loading?: boolean;
  isShowLoading?: boolean;
  infiniteScrollBtnText?: string;
  pageRangeDisplayed?: number;
  children: ReactNode;
}

// todo : 리팩토링 후 List 정책 논의 필요
// 1. 페이지네이션 정책이 모든 화면에서 동일하기 때문에 pageRangeDisplayed 속성 불필요해보임
// 2. 데이터 요청 시 스피너 사용할지
// 3. 인피니티 스크롤 정책 논의 필요
// 4. List 컴포넌트 리팩토링
const List: FC<ListProps> = (props: ListProps) => {
  const {
    className,
    onChange,
    pagination,
    loading = false,
    isShowLoading = true,
    pageRangeDisplayed,
    children,
  } = props;

  return (
    <ListStyle className={className}>
      {children}

      {!loading && pagination && pagination.pageCount > 1 && (
        <ReactPaginate
          containerClassName="list-pagination"
          pageClassName="list-page-number"
          previousLabel={<Icon src={ARROW_LEFT_OUTLINE_BLACK_IMAGE_PATH} />}
          nextLabel={<Icon src={ARROW_RIGHT_OUTLINE_BLACK_IMAGE_PATH} />}
          marginPagesDisplayed={1}
          pageCount={pagination?.pageCount}
          forcePage={pagination?.currentPage}
          onPageChange={onChange}
          pageRangeDisplayed={pageRangeDisplayed ? pageRangeDisplayed : LIST_PAGE_RANGE_DISPLAYED[DeviceSize.DESKTOP]}
        />
      )}

      {isShowLoading && loading && <LoadingSpinner />}
    </ListStyle>
  );
};

export default memo(List);
