import React, { FC, useMemo } from "react";

import Product from "@components/common/Product";
import Img from "@components/common/Img";
import { searchBeforeRecommendedProductListDataType, searchProductListCommonDataType } from "@swr/types/searchTypes";

interface ISearchRecommendedProductListProps {
  recommendProductList: searchProductListCommonDataType[] | searchBeforeRecommendedProductListDataType[];
  columnGap?: number;
}

const SearchRecommendedProductList: FC<ISearchRecommendedProductListProps> = (props) => {
  const { recommendProductList, columnGap = 16 } = props;

  const { List, VerticalCard } = Product;

  const productList = useMemo(() => {
    return recommendProductList.map((product) => (
      <li key={product.id}>
        <VerticalCard
          id={product.id}
          image={<Img src={product.imgUrl} width={300} height={300} />}
          productName={product.productName}
          brandName={product.storeName}
        />
      </li>
    ));
  }, []);

  return (
    <List column={5} columnGap={columnGap} onChange={() => {}}>
      {productList}
    </List>
  );
};

export default SearchRecommendedProductList;
