import useCategoryTree from "@swr/hooks/product/useCategoryTree";
import { PRODUCT_SORT_TYPE } from "@constants/enum/productEnum";

export type NormalCategoryType = {
  id: number;
  name: string;
  sortType: PRODUCT_SORT_TYPE;
};
export type EmphasisCategoryType = {
  id: number;
  name: string;
  sortType: PRODUCT_SORT_TYPE;
  src: string;
};
const useCategoryTreeViewModel = () => {
  const { data } = useCategoryTree();

  // 에러처리 - 에러 발생 시 원버튼 모달이 아닌 LNB 비노출로 에러 처리
  if (!data) return null;

  // 서버에서 받아온 데이터를 뷰에 맞는 형태로 변환해주는 함수
  const convertData = () => {
    const normalCategoryList: NormalCategoryType[] = data.categoriesTree
      .filter((item) => item.type === 0)
      .map(({ id, name, sortType }) => ({ id, name, sortType }));
    const emphasisCategoryList: EmphasisCategoryType[] = data.categoriesTree
      .filter((item) => item.type === 1)
      .map(({ id, name, sortType, categoryAttachments }) => ({ id, name, sortType, src: categoryAttachments[0]?.url }));

    return { normalCategoryList, emphasisCategoryList };
  };

  return convertData();
};

export default useCategoryTreeViewModel;
