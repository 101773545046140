import Image from "next/legacy/image";

import styled from "@styles/themedComponents";
import { Theme } from "@styles/theme";

export const ImageStyle = styled(Image)`
  background-color: ${(props): Theme["color"]["gray_07"] => props.theme.color.gray_07};
`;

export const DefaultImageStyle = styled.div`
  display: inline-block;
  background-color: ${(props): Theme["color"]["gray_07"] => props.theme.color.gray_07};
`;
