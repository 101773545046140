import React, { FC } from "react";
import { useDispatch } from "react-redux";
import Router, { useRouter } from "next/router";

import { searchModalVisible } from "@store/modules/search";
import { BRAND_PAGE_URL_PATH, SEARCH_RESULT_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { BRAND_DEFAULT_IMAGE_PATH } from "@constants/static/images";
import { searchBeforeAutoCompleteListDataCommonType } from "@swr/types/searchTypes";
import {
  BrandCategoryStyle,
  BrandImageStyle,
  BrandListStyle,
  BrandNameStyle,
  BrandWrapStyle,
  HorizonBorder,
} from "./style";

interface IAutoCompletedSearchBrandProps {
  brandList: ({
    // 브랜드 인식 index id
    storeId: number;
    // 브랜드 대표 이미지
    imgUrl: string;
  } & searchBeforeAutoCompleteListDataCommonType)[];
}

const AutoCompletedSearchBrand: FC<IAutoCompletedSearchBrandProps> = (props) => {
  const { brandList } = props;
  const dispatch = useDispatch();
  const router = useRouter();

  Router.events.on("routeChangeStart", () => {
    dispatch(searchModalVisible({ isSearchModalVisible: false }));
  });

  return (
    <>
      {brandList.map((brand) => (
        <BrandListStyle key={brand.storeId} className="auto-completed-search-list">
          <BrandWrapStyle
            onClick={() => {
              // 검색 페이지 내부에서는 history stack 쌓지 않음
              if (router.pathname.includes(SEARCH_RESULT_PAGE_URL_PATH)) {
                Router.replace(`${BRAND_PAGE_URL_PATH}/${brand.storeId}`);
              } else {
                Router.push(`${BRAND_PAGE_URL_PATH}/${brand.storeId}`);
              }
            }}
          >
            <BrandImageStyle src={brand.imgUrl || BRAND_DEFAULT_IMAGE_PATH} width={32} height={32} />
            <BrandNameStyle dangerouslySetInnerHTML={{ __html: brand.displayText }} />
            <HorizonBorder />
            <BrandCategoryStyle>브랜드</BrandCategoryStyle>
          </BrandWrapStyle>
        </BrandListStyle>
      ))}
    </>
  );
};

export default AutoCompletedSearchBrand;
