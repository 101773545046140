import { mypageApiV2 } from "@amondz/apis-v2";
import { createQueryKeys } from "@lukemorales/query-key-factory";

/**
 * 공지
 */
export const mypageQuery = createQueryKeys("mypage", {
  memberSummary: {
    queryKey: null,
    queryFn: mypageApiV2.membersSummary,
  },
});
