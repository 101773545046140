import styled from "@styles/themedComponents";

interface IListStyle {
  calcGridColumns: string;
  column: number;
  rowGap?: number;
  columnGap?: number;
}
export const InfiniteListStyle = styled.ul<IListStyle>`
  display: grid;
  grid-template-columns: repeat(${({ column, calcGridColumns }) => `${column}, calc(${calcGridColumns})`});
  row-gap: ${({ rowGap = 0 }) => rowGap}px;
  column-gap: ${({ columnGap = 0 }) => columnGap}px;
`;

export const SpinnerWrapperStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;
