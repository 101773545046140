import styled from "@styles/themedComponents";

export const HorizontalCardStyle = styled.article`
  display: flex;
  justify-content: space-between;
`;
export const CardImageAreaStyle = styled.figure`
  display: flex;
  flex-shrink: 0;
  width: 100px;
  margin: 0 16px 0 0;
  padding: 0;

  img {
    border-radius: 2px;
  }
`;

export const CardInfoAreaStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  font-family: ${({ theme }) => theme.fonts.notoSansCJKkr};
`;

export const CardButtonListAreaStyle = styled.div`
  display: flex;
  height: 100px;

  align-items: center;
  font-family: ${({ theme }) => theme.fonts.notoSansCJKkr};
`;

export const BrandNameStyle = styled.strong`
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: -0.3px;
`;

interface IProductNameStyleProps {
  isEllipsis?: boolean;
}
export const ProductNameStyle = styled.p<IProductNameStyleProps>`
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.5px;
  ${({ isEllipsis }) =>
    isEllipsis
      ? `overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        height: 24px;`
      : ""};
`;

export const PriceStyle = styled.p`
  font-family: ${({ theme }) => theme.fonts.barlow};
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;

  &:after {
    content: "원";
    font-family: ${({ theme }) => theme.fonts.notoSansCJKkr};
    font-size: 11px;
    font-weight: normal;
    letter-spacing: -0.5px;
    text-decoration: none;
  }
`;
export const CountStyle = styled.p`
  font-family: ${({ theme }) => theme.fonts.barlow};
  font-size: 12px;
  line-height: 17px;
  color: ${({ theme }) => theme.color.gray_01};

  &:before {
    content: "/";
    margin: 0 2px;
  }
  &:after {
    content: "개";
    font-family: ${({ theme }) => theme.fonts.notoSansCJKkr};
    font-size: 11px;
    font-weight: normal;
    letter-spacing: -0.5px;
    text-decoration: none;
  }
`;

export const OptionAndMessageStyle = styled.p`
  font-size: 12px;
  letter-spacing: -0.3px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.gray_01};
`;

export const DateStyle = styled.p`
  font-size: 11px;
  letter-spacing: -0.3px;
  line-height: 13px;
  color: ${({ theme }) => theme.color.gray_01};
`;

export const TodayBadgeStyle = styled.div`
  display: flex;
  align-items: center;
`;
