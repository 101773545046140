import { useQueries, useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import {
  BrandsFindOneResponseBrandDto,
  BrandsFindOneResponseDto,
  DeliveryInfoResponseType,
  productApiV2,
  ProductDetailsResponseType,
  ProductFindOneResponseProductDto,
  ProductPriceBenefitsResponseDto,
} from "@amondz/apis-v2";
import {
  productAPI,
  ProductAdditionalResponseType,
  GetProductCustomerServiceRequestType,
  GetProductCustomerServiceResponseType,
  ProductCustomerServiceTermType,
} from "@amondz/apis-v1";
import { formatAddressByType } from "@amondz/utils/src/common/address";
import { formatBusinessLicenseNumber, formatCustomerServiceNumber } from "@amondz/utils";
import { ProductDetailItemType } from "@amondz/types";
import { productQueryKeys } from "./keys";
import { brandQuery } from "../brand";

export type SelectedProductAdditionalResponse = Pick<
  ProductDetailItemType,
  "productDetail" | "productOption" | "notice" | "brand" | "tag" | "recommendList" | "detailContentsList"
>;

export const useProductBenefits = (productId: number): UseQueryResult<ProductPriceBenefitsResponseDto> => {
  return useQuery(
    productQueryKeys.priceBenefits(productId),
    (): Promise<ProductPriceBenefitsResponseDto> => productApiV2.getPriceBenefits(productId),
    {
      enabled: !!productId,
    },
  );
};

export const useProductDetail = (
  productId: number,
): [UseQueryResult<SelectedProductAdditionalResponse>, UseQueryResult<ProductFindOneResponseProductDto>] => {
  return useQueries({
    queries: [
      {
        queryKey: productQueryKeys.productAdditional(productId),
        queryFn: (): Promise<ProductAdditionalResponseType> => productAPI.getProductAdditional({ productId }),
        select: (result: ProductAdditionalResponseType): SelectedProductAdditionalResponse => {
          const { data } = result;
          return {
            productDetail: data.productDetail,
            productOption: data.productOption,
            notice: data.notice,
            brand: data.brand,
            tag: data.tag,
            recommendList: data.recommendList,
            detailContentsList: data.detailContentsList,
          };
        },
      },
      {
        queryKey: productQueryKeys.productBasic(productId),
        queryFn: (): Promise<ProductDetailsResponseType> => productApiV2.getProductDetail(productId),
        select: (result: ProductDetailsResponseType): ProductFindOneResponseProductDto => result.product,
      },
    ],
  });
};

export const useProductDelivery = (productId: number): UseQueryResult<DeliveryInfoResponseType> => {
  return useQuery(
    productQueryKeys.productDelivery(productId),
    (): Promise<DeliveryInfoResponseType> => productApiV2.getProductDeliveryInfo(productId),
  );
};

type FormattedCustomerServiceResponseType = {
  returnExchangeInfo: {
    asPhone: string;
  } & ProductCustomerServiceTermType;
};

type FormattedBrandResponseType = {
  brandInfo: {
    address: string;
    businessLicenseNumber: string;
  } & BrandsFindOneResponseBrandDto;
};
export const useProductReturnExchangeDeliveryInfo = (
  payload: GetProductCustomerServiceRequestType,
): [UseQueryResult<FormattedCustomerServiceResponseType>, UseQueryResult<FormattedBrandResponseType>] => {
  return useQueries({
    queries: [
      {
        queryKey: productQueryKeys.productCustomerService(payload),
        queryFn: (): Promise<AxiosResponse<GetProductCustomerServiceResponseType>> =>
          productAPI.getProductCustomerServiceInfo(payload),
        select: (
          response: AxiosResponse<GetProductCustomerServiceResponseType>,
        ): FormattedCustomerServiceResponseType => {
          const { data } = response;

          const asPhoneFormatted =
            data && "asPhone" in data && typeof data.asPhone === "string"
              ? formatCustomerServiceNumber(data.asPhone)
              : "";

          return {
            returnExchangeInfo: {
              ...data,
              asPhone: asPhoneFormatted,
            },
          };
        },
        enabled: !!payload.storeId && !!payload.productId,
      },
      {
        queryKey: brandQuery.detail(payload.storeId).queryKey,
        queryFn: brandQuery.detail(payload.storeId).queryFn,
        select: (result: BrandsFindOneResponseDto): FormattedBrandResponseType => {
          return {
            brandInfo: {
              ...result.brand,
              address: formatAddressByType(result.brand.addresses, "brand"),
              businessLicenseNumber: formatBusinessLicenseNumber(result.brand.businessLicenseNumber),
            },
          };
        },
        enabled: !!payload.storeId,
      },
    ],
  });
};
