import { useQuery } from "@tanstack/react-query";
import { productApiV2, ProductDownloadableCouponsResponseDto } from "@amondz/apis-v2";
import produce from "immer";
import { CouponListItemType } from "@amondz/types";
import { couponQueryKeys } from "./keys";

export const useCouponList = (productId: number) => {
  return useQuery<ProductDownloadableCouponsResponseDto, Error, CouponListItemType[]>(
    couponQueryKeys.coupons(productId),
    () => productApiV2.getCouponList(productId),
    {
      enabled: !!productId,
      select: (data: ProductDownloadableCouponsResponseDto) => produce(data.productDownloadableCoupons, draft => {
        return draft.map((couponObj) => ({
          ...couponObj.coupon,
          appliedDiscountAmount: couponObj.appliedDiscountAmount
        }));
      }),
    }
  )
};
