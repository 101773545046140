import ButtonCover from "@components/common/cover/ButtonCover";
import Icon from "@components/common/Icon";
import styled from "@styles/themedComponents";

interface ISearchInputClearButtonStyleProps {
  isSearchKeywordExisted: boolean;
  isAutoCompleteDataExisted?: boolean;
}

interface ISearchModalStyleProps extends ISearchInputClearButtonStyleProps {
  isRecentSearchKeywordListExisted: boolean;
}

export const SearchModalStyle = styled.section<ISearchModalStyleProps>`
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 651px;
  z-index: ${({ theme }) => theme.zIndex.modalBox};
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  background-color: white;
  padding: ${({ isSearchKeywordExisted }) => (isSearchKeywordExisted ? "20px 16px 0" : "20px 16px 40px")};
  border-radius: 10px;
  box-shadow: 0 2px 11px 0 rgba(197, 197, 197, 0.5);
  height: ${({ isAutoCompleteDataExisted, isSearchKeywordExisted }) =>
    isAutoCompleteDataExisted || !isSearchKeywordExisted ? "auto" : "73px"};

  .recommended-search-list-wrapper {
    .recommended-search-list {
      padding-top: 0;
      margin-top: 0;
    }
  }

  .recent-search-word-list-wrapper {
    & > header {
      margin-bottom: 6px;
    }
  }

  .recommended-product-list-wrapper {
    margin-top: ${({ isRecentSearchKeywordListExisted }) => (isRecentSearchKeywordListExisted ? "40px" : 0)};
  }
`;

export const SearchFormStyle = styled.form`
  position: relative;
`;

export const HiddenInputStyle = styled.input`
  position: absolute;
  top: -100px;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
`;

export const SearchInputSectionStyle = styled.div<ISearchInputClearButtonStyleProps>`
  margin-bottom: ${({ isSearchKeywordExisted, isAutoCompleteDataExisted }) =>
    isSearchKeywordExisted ? (!isAutoCompleteDataExisted ? "13px" : "10px") : "20px"};

  form {
    width: 574px;

    input {
      font-size: 16px !important;
      font-weight: bold;
      padding-top: 8px;
      padding-bottom: 8px;
      height: 40px;

      &::placeholder {
        font-size: 16px;
        font-weight: normal;
        letter-spacing: -0.5px;
      }
    }
  }
`;

export const SearchInputClearButtonStyle = styled(Icon)<ISearchInputClearButtonStyleProps>`
  display: ${({ isSearchKeywordExisted }) => (isSearchKeywordExisted ? "block" : "none")};
  position: absolute;
  width: 24px;
  height: 24px;
  top: 25px;
  right: 101px;
  cursor: pointer;
`;

export const SearchModalCloseButtonStyle = styled(ButtonCover)`
  position: absolute;
  top: 28px;
  right: 16px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.color.gray_01};
`;

export const SearchInputSubmitButton = styled(ButtonCover)`
  position: absolute;
  top: 8px;
  right: 0;
  width: 24px;
  height: 24px;
`;

export const SearchIconStyle = styled(Icon)`
  width: 20px;
  height: 20px;
`;

export const RecentSearchListClearButton = styled(ButtonCover)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.5px;
  color: ${(props) => props.theme.color.gray_01};
`;
