import Icon from "@components/common/Icon";
import styled from "@styles/themedComponents";

interface ISearchTextStyleProps {
  isSearchKeywordExisted: boolean;
}

export const SearchBoxStyle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_07};
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  cursor: pointer;
`;

export const SearchContentStyle = styled.div`
  width: 345px;
  padding: 8px 0;
`;

export const SearchTextStyle = styled.div<ISearchTextStyleProps>`
  width: 309px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: ${({ theme, isSearchKeywordExisted }) =>
    isSearchKeywordExisted ? theme.color.amondz_black : theme.color.gray_01};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SearchIconStyle = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 0;
  width: 20px;
  height: 20px;
`;
