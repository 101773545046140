import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import produce from "immer";
import { LikeProductItemType } from "@amondz/types";
import { LikeProductResponse, productAPI as productAPIV1 } from "@amondz/apis-v1";
import { LikeProductListStateType, productApiV2 } from "@amondz/apis-v2";
import { productQueryKeys } from "./keys";

const likeProductListFetcher = async (pageNumber: number, pageSize: number): Promise<LikeProductListStateType> => {
  const data: LikeProductListStateType = await productApiV2.likeProductList({ pageNumber, pageSize });
  return {
    ...data,
    likeProducts: produce(data.likeProducts, (draft) => {
      draft?.forEach((item) => {
        item.likeFlag = true;
        return item;
      });
    }),
  };
};

export const useLikeProductList = (
  pageNumber: number,
  pageSize: number,
  option?: {
    onSuccess?: (data: LikeProductListStateType) => void;
    onLikeSuccess?: (res: LikeProductResponse, product: LikeProductItemType) => void;
  },
): UseQueryResult<LikeProductListStateType, Error> & { likeProduct: (data: LikeProductItemType) => void } => {
  const queryClient = useQueryClient();
  const result = useQuery<LikeProductListStateType, Error>(
    productQueryKeys.like(pageNumber, pageSize),
    () => likeProductListFetcher(pageNumber, pageSize),
    {
      ...option,
      cacheTime: 0,
    },
  );

  const { mutate } = useMutation({
    mutationFn: (product: LikeProductItemType) => productAPIV1.likeProduct({ productId: product.id }),
    onMutate: async (product: LikeProductItemType) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: productQueryKeys.like(pageNumber, pageSize) });

      // Snapshot the previous value
      const previousData: LikeProductListStateType | undefined = queryClient.getQueryData(
        productQueryKeys.like(pageNumber, pageSize),
      );

      if (previousData) {
        // Optimistically update to the new value
        queryClient.setQueryData(
          productQueryKeys.like(pageNumber, pageSize),
          produce(previousData, (draft) => {
            const target = draft.likeProducts.find((item) => item.id === product.id);
            if (!target) return;
            target.likeFlag = !target.likeFlag;
          }),
        );
      }

      // Return a context
      return { previousData, product };
    },
    onSuccess: option?.onLikeSuccess,
    onError: (err, targetId, context) => {
      if (!context) return;
      queryClient.setQueryData(productQueryKeys.like(pageNumber, pageSize), context.previousData);
    },
  });

  return { ...result, likeProduct: mutate };
};
