import Icon from "@components/common/Icon";
import styled from "@styles/themedComponents";

export const WordListStyle = styled.li`
  margin-left: -16px;
  width: calc(100% + 32px);
  height: 44px;
  cursor: pointer;
  position: relative;

  &:last-child {
    border-radius: 10px;
  }

  &.selected {
    background-color: ${({ theme }) => theme.color.gray_08};
  }
`;

export const WordWrapStyle = styled.a`
  display: flex;
  align-items: center;
  line-height: 1.71;
  width: 100%;
  height: 100%;
  padding: 10px 16px 10px 24px;
`;

export const SearchIconStyle = styled(Icon)`
  width: 16px;
  height: 16px;
`;

export const WordNameStyle = styled.span`
  font-size: 14px;
  letter-spacing: -0.5px;
  margin-left: 24px;
  padding: 10px 0;
`;
