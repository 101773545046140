import styled from "@styles/themedComponents";

export const InputStyle = styled.div`
  & > label {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.5px;
    pointer-events: none;

    .required {
      color: ${(props) => props.theme.color.amondz_purple};
    }
  }

  & > div {
    position: relative;

    input {
      width: 100%;
      padding: 9px 24px 8px 0;
      font-size: 13px;
      line-height: 19px;
      border: none;
      border-bottom: 1px solid ${(props) => props.theme.color.gray_07};
      outline: none;
      background: transparent;
      color: ${(props) => props.theme.color.amondz_black};

      &:not(:read-only):focus {
        border-bottom: 1px solid ${(props) => props.theme.color.amondz_black};
      }

      &::-webkit-input-placeholder {
        color: ${(props) => props.theme.color.gray_01};
      }

      &::-moz-placeholder {
        color: ${(props) => props.theme.color.gray_01};
      }

      &::-ms-input-placeholder {
        color: ${(props) => props.theme.color.gray_01};
      }

      &::placeholder {
        color: ${(props) => props.theme.color.gray_01};
      }
    }

    .add-on {
      position: absolute;
      top: 6px;
      right: 0;
      z-index: ${(props) => props.theme.zIndex.inputAddOn};

      .timer {
        font-family: ${(props) => props.theme.fonts.barlow};
        font-size: 11px;
        color: ${(props) => props.theme.color.amondz_orange};
      }
    }

    .clear-input-btn {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 6px;
      right: 0;
      cursor: pointer;
      display: block;
    }
  }
`;
