import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { brandApiV2, LikeBrandResponseDto } from "@amondz/apis-v2";
import { AxiosError } from "axios";
interface IUseLikeBrandProps {
  onSuccessCallback?: (data: { totalCount: number }, variable: number) => void;
  onErrorCallback?: (error: unknown) => void;
}

export const useLikeBrand = ({
  onSuccessCallback,
  onErrorCallback,
}: IUseLikeBrandProps): UseMutationResult<LikeBrandResponseDto, AxiosError, number, unknown> => {
  return useMutation({
    mutationFn: (brandId: number) => brandApiV2.likeBrand(brandId),
    onSuccess: (data, variables) => {
      if (data && onSuccessCallback) {
        onSuccessCallback(data, variables);
      }
    },
    onError: (error) => {
      if (error && onErrorCallback) {
        onErrorCallback(error);
      }
    },
  });
};
