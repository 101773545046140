import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { homeApiV2, HomeTabsFindAllResponseBodyHomeTabDto } from "@amondz/apis-v2";
import { HomeTab, HomeTabListError, HomeTabListType } from "@amondz/types";
import { mainHomeQueryKeys } from "./keys";
import { CustomAxiosError } from "@amondz/types/src/http";

const homeTabConverter = {
  serverToClient: (homeTab: HomeTabsFindAllResponseBodyHomeTabDto): HomeTab => ({
    id: homeTab.id,
    linkId: homeTab.linkId,
    linkType: homeTab.linkType,
    no: homeTab.no,
    title: homeTab.title,
  }),
};
export const useHomeTabList = (): UseQueryResult<HomeTabListType, CustomAxiosError<HomeTabListError>> => {
  return useQuery(mainHomeQueryKeys.tabList(), homeApiV2.getHomeTabList, {
    select: (res) => res.homeTabs.map((homeTab) => homeTabConverter.serverToClient(homeTab)),
  });
};
