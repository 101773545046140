// todo: 삭제예정
/**
 * 기본 이미지 사이즈
 * width: 256
 * height: 256
 */
export const DEFAULT_IMAGE_SIZE = {
  WIDTH: 256,
  HEIGHT: 256,
};

/**
 * 큰 이미지 사이즈
 * width: 640
 * height: 640
 */
export const LARGE_IMAGE_SIZE = {
  WIDTH: 640,
  HEIGHT: 640,
};

// todo: 여기까지 삭제예정

export type ImageSizeType = "DEFAULT" | "LARGE" | "LARGEST";
export const IMAGE_SIZE = {
  /**
   * 기본 이미지 사이즈
   * width: 256
   * height: 256
   */
  DEFAULT: {
    WIDTH: 256,
    HEIGHT: 256,
  },
  /**
   * 큰 이미지 사이즈
   * width: 640
   * height: 640
   */
  LARGE: {
    WIDTH: 640,
    HEIGHT: 640,
  },
  /**
   * 가장 큰 이미지 사이즈
   * width: 1080
   * height: 1080
   */
  LARGEST: {
    WIDTH: 1080,
    HEIGHT: 1080,
  },
};
