import * as React from "react";
import { useState } from "react";

import { PRODUCT_LIST_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import GNB from "./GNB";
import LNB from "./LNB";
import useCategoryTreeViewModel from "./useCategoryTreeViewModel";
import { GNB_MENU_ID, HIDE_LNB_STATE } from "./constants";
import { GNB_MENU_ID_TYPE, ISetLnbState } from "./type";
import { MainMenuStyle } from "./style";

export interface IMainMenuProps {
  className?: string;
}

const MainMenu = ({ className }: IMainMenuProps): JSX.Element => {
  const data = useCategoryTreeViewModel();
  const [lnbState, setLnbState] = useState<ISetLnbState>(HIDE_LNB_STATE);

  const handleMouseEnter = (id: GNB_MENU_ID_TYPE) => {
    setLnbState({
      isVisible: true,
      triggerId: id,
    });
  };

  const handleMouseLeave = () => {
    setLnbState(HIDE_LNB_STATE);
  };

  let categoryUrl = PRODUCT_LIST_PAGE_URL_PATH;
  if (data) {
    const { id, sortType } = data.normalCategoryList[0];
    categoryUrl += `?category=${id}&sortType=${sortType}`;
  }

  return (
    <MainMenuStyle className={className}>
      <GNB className="GNB" categoryUrl={categoryUrl} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      {data !== null && (
        <LNB
          className="LNB"
          id={lnbState.triggerId}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          isVisible={lnbState.isVisible && lnbState.triggerId === GNB_MENU_ID.CATEGORY}
        >
          <LNB.Category normalCategoryList={data.normalCategoryList} emphasisCategoryList={data.emphasisCategoryList} />
        </LNB>
      )}
    </MainMenuStyle>
  );
};

export default MainMenu;
