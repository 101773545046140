import * as React from "react";
import { FC, memo, MouseEventHandler, ReactNode } from "react";
import { ButtonCoverStyle } from "./style";

export interface IButtonCoverStyleProps {
  className?: string;
  onClick: MouseEventHandler;
  type?: "button" | "submit" | "reset";
  children?: ReactNode;
}

const Button: FC<IButtonCoverStyleProps> = (props: IButtonCoverStyleProps) => {
  const { className, onClick, type = "button", children } = props;

  return (
    <ButtonCoverStyle className={className} onClick={onClick} type={type}>
      {children}
    </ButtonCoverStyle>
  );
};

export default memo(Button);
