import * as React from "react";
import { FC, memo } from "react";
import { IBaseIconProps } from "@components/icon/type";

const AmondzLogo: FC<IBaseIconProps> = ({ className, width = 97, height = 20, fill }) => {
  return (
    <svg
      className={className}
      role="img"
      aria-label="amondz-logo"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 97 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="r7gwngiowa" d="M0 0h14.473v14.59H0z" />
      </defs>
      <g fillRule="evenodd">
        <path d="M86.457 5.188v2.815h6.567l-6.847 9.376v1.992H97v-2.816h-6.96l6.68-9.375V5.188zM76.088 5.188c2.12 0 3.725.887 4.759 2.33V0h2.938v19.417h-2.938v-1.995c-1.034 1.47-2.639 2.356-4.76 2.356-3.7 0-6.775-3.19-6.775-7.296 0-4.132 3.075-7.294 6.776-7.294m4.759 7.294c0-2.579-1.85-4.438-4.298-4.438-2.45 0-4.298 1.86-4.298 4.438 0 2.58 1.849 4.44 4.298 4.44s4.298-1.86 4.298-4.44M67.668 10.902v8.515h-2.94v-8.211c0-2.108-1.197-3.217-3.045-3.217-1.931 0-3.454 1.167-3.454 3.996v7.432h-2.94V5.547h2.94v1.775c.897-1.44 2.366-2.134 4.215-2.134 3.047 0 5.224 2.108 5.224 5.714M51.02 11.543c.015-1.285-.45-2.393-1.308-3.13-.65-.557-1.48-.851-2.398-.851-1.514 0-3.08.802-4.187 2.15-1.846 2.244-1.897 5.313-.11 6.84.65.558 1.48.852 2.398.852 1.514 0 3.08-.802 4.187-2.15.9-1.092 1.404-2.412 1.418-3.71m-4.657-6.578c4.15 0 7.4 3.3 7.4 7.516 0 4.215-3.25 7.518-7.4 7.518-4.148 0-7.398-3.303-7.398-7.518 0-4.216 3.25-7.516 7.398-7.516M37.134 10.819v8.598h-2.94v-8.405c0-1.94-1.033-3.023-2.638-3.023-1.741 0-2.964 1.138-2.964 3.661v7.767h-2.939v-8.405c0-1.94-.951-3.023-2.556-3.023-1.66 0-3.021 1.138-3.021 3.661v7.767H17.14V5.547h2.936v1.665c.872-1.36 2.205-2.024 3.89-2.024 1.77 0 3.074.803 3.864 2.192.898-1.444 2.339-2.192 4.162-2.192 3.072 0 5.142 2.163 5.142 5.63" />
        <g transform="translate(0 5.188)">
          <mask id="59awzkkpeb" fill="#fff">
            <use xlinkHref="#r7gwngiowa" />
          </mask>
          <path
            d="M11.534 7.293c0-2.578-1.849-4.437-4.298-4.437-2.448 0-4.297 1.859-4.297 4.437 0 2.582 1.849 4.44 4.297 4.44 2.45 0 4.298-1.858 4.298-4.44zM14.473.358v13.87h-2.939v-1.995c-1.033 1.47-2.639 2.356-4.76 2.356C3.075 14.59 0 11.4 0 7.293 0 3.162 3.075 0 6.775 0c2.12 0 3.726.887 4.76 2.33V.358h2.938z"
            mask="url(#59awzkkpeb)"
          />
        </g>
      </g>
    </svg>
  );
};

export default memo(AmondzLogo);
