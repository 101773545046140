import styled, { css, withProps } from "@styles/themedComponents";
import { BUTTON_COLOR, BUTTON_SIZE } from "@constants/enum/baseEnums";

export interface IButtonStyleProps {
  color: BUTTON_COLOR;
  size: BUTTON_SIZE;
  isLoading: boolean;
}

export const ButtonStyle = withProps<IButtonStyleProps, HTMLButtonElement>(styled.button)`
    border-radius: 2px;
    outline: none;
    border: 1px solid transparent;
    text-align: center;
    cursor: pointer;
    
    ${(props) =>
      props.color === BUTTON_COLOR.WHITE &&
      css`
        background-color: ${props.theme.color.amondz_white};
        color: ${props.theme.color.amondz_black};
        border: 1px solid ${props.theme.color.gray_05};
      `};
    
    ${(props) =>
      props.color === BUTTON_COLOR.GRAY &&
      css`
        background-color: ${props.theme.color.gray_06};
        color: ${props.theme.color.amondz_black};
        border: ${props.theme.color.gray_05};
      `};
    
    ${(props) =>
      props.color === BUTTON_COLOR.BLACK &&
      css`
        background-color: ${props.theme.color.amondz_black};
        color: ${props.theme.color.amondz_white};
      `};
    
     ${(props) =>
       props.size === BUTTON_SIZE.MEDIUM &&
       css`
         font-size: 12px;
         font-weight: 500;
         letter-spacing: -0.5px;
         height: 32px;
         width: 80px;
         line-height: 32px;
       `};
    
    ${(props) =>
      props.size === BUTTON_SIZE.LARGE &&
      css`
        font-size: 13px;
        font-weight: 500;
        letter-spacing: -0.3px;
        height: 40px;
        width: 181px;
        line-height: 40px;
      `};
    
    &:disabled {
        background-color: ${(props) => props.theme.color.gray_04};
    }
    
    ${(props) =>
      props.isLoading &&
      css`
        background-color: ${props.theme.color.gray_04};
      `};
    
    .spinner-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
    }
`;
