import React from "react";
import {
  ContentsStyle,
  TitleStyle,
  LabelStyle,
  SectionItemStyle,
  SectionStyle,
  SubLabelStyle,
  SubContentsStyle,
  SubItemWrapStyle,
  DividerStyle,
} from "./style";
import { SectionItem } from "../types";

export interface SectionProps {
  title?: string;
  items: SectionItem[];
  showDivider?: boolean;
}

const InformationSection = ({ title, items = [], showDivider = true }: SectionProps) => {
  if (!items.length) {
    return null;
  }
  return (
    <>
      <SectionStyle>
        {title && <TitleStyle>{title}</TitleStyle>}
        {items.map((item, index) => (
          <SectionItemStyle data-testid="section-item" key={index}>
            <LabelStyle>{item.label}</LabelStyle>
            <ContentsStyle>{item.content}</ContentsStyle>
            {item.subItems && (
              <SubItemWrapStyle>
                {item.subItems.map((subItem, subIndex) => (
                  <div key={subIndex}>
                    <SubLabelStyle>{subItem.label}</SubLabelStyle>
                    <SubContentsStyle>{subItem.content}</SubContentsStyle>
                  </div>
                ))}
              </SubItemWrapStyle>
            )}
          </SectionItemStyle>
        ))}
      </SectionStyle>
      <DividerStyle canShow={showDivider} type={"full-thin"} />
    </>
  );
};

export default InformationSection;
