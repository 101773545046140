import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reviewAPI } from "@amondz/apis-v1";

interface IReviewDeleteMutate {
  onSuccessCallback?: (reviewId: number) => void;
  onFailureCallback?: (data: { status: number }) => void;
}

export const useDeleteReview = ({ onSuccessCallback, onFailureCallback }: IReviewDeleteMutate) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (reviewId: number) => await reviewAPI.deleteReview({ reviewId }),
    onSuccess: (data, variables) => {
      if (data.status !== 1 && onFailureCallback) {
        onFailureCallback(data);
        return;
      }
      if (data.status === 1 && onSuccessCallback) {
        onSuccessCallback(variables);
      }
    },
  });
};
