import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { productApiV2, ProductQnasRequestParamType } from "@amondz/apis-v2";
import { ProductQnaListResponseType } from "@amondz/types";
import {
  DeleteQnaProductType,
  InsertProductQnaRequestType,
  productAPI,
  UpdateQnaProductType
} from "@amondz/apis-v1";
import { productQueryKeys } from "./keys";
import { QueryOptions } from "../type";

interface IUseProductQnaQuery<SelectResultType> {
  options?: QueryOptions<ProductQnaListResponseType, unknown, SelectResultType>
  params: ProductQnasRequestParamType;
}

export const useProductQnaQuery = <SelectResultType>(
  payload: IUseProductQnaQuery<SelectResultType>
): UseQueryResult<SelectResultType, unknown> => {
  const { params, options } = payload;
  const { productId, ...rest} = params;
  return useQuery<ProductQnaListResponseType, unknown, SelectResultType>(
    productQueryKeys.productQnas({productId, ...rest}),
    () => productApiV2.getProductQnaList({ productId, ...rest}),
    {
      ...options,
      enabled: !!productId,
      keepPreviousData: true,
    },
  );
};

interface IUseProductQnaMutate {
  onSuccessCallback?: () => void;
  onFailureCallback: (data: { status: number }) => void;
}

export const useProductQnaMutate = ({ onSuccessCallback, onFailureCallback }: IUseProductQnaMutate) => {
  const queryClient = useQueryClient();

  const insertProductQnaMutation = useMutation({
    mutationFn: (payload: InsertProductQnaRequestType) => productAPI.insertProductQna(payload),
    onSuccess: (data) => {
      // todo: 슈퍼패치 공통 모듈에서 에러 케이스를 exception 하도록 수정 후 onError 적용
      if (data.status !== 1) {
        if (onFailureCallback) {
          onFailureCallback(data);
          return;
        }
      }
      if (data.status === 1 && onSuccessCallback) {
        onSuccessCallback();
      }
    }
  });

  const updateProductQnaMutation = useMutation({
    mutationFn: (payload: UpdateQnaProductType) => productAPI.updateProductQna(payload),
    onSuccess: (data) => {
      if (data.status !== 1) {
        if (onFailureCallback) {
          onFailureCallback(data);
          return;
        }
      }
      if (data.status === 1 && onSuccessCallback) {
        onSuccessCallback();
      }
    }
  });
  const deleteProductQnaMutation = useMutation({
    mutationFn: (payload: DeleteQnaProductType) => productAPI.deleteProductQna(payload),
    onSuccess: (data) => {
      if (data.status !== 1) {
        if (onFailureCallback) {
          onFailureCallback(data);
          return;
        }
      }
      if (data.status === 1 && onSuccessCallback) {
        onSuccessCallback();
      }
    }
  })
  return { insertProductQnaMutation, updateProductQnaMutation, deleteProductQnaMutation };
};
