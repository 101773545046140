import { luckyDrawApiV2 } from "@amondz/apis-v2";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import marketingService from "@amondz/marketing-service/src/service";
import { eventType } from "@amondz/marketing-service";
import { luckyDrawQueryKeys } from "./keys";

export const useApplyLuckyDraw = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (code: string) => luckyDrawApiV2.apply(code),
    onSuccess: (data, variables) => {
      marketingService(eventType.applyEvent, { code: variables });
      queryClient.invalidateQueries(luckyDrawQueryKeys.base);
    },
  });
};
