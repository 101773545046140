import styled from "@styles/themedComponents";

export const GlobalNavigationBarStyle = styled.div`
  padding: 0 60px;
  border-bottom: ${({ theme }) => `1px solid ${theme.adsColor.gray_02}`};
`;

//높이 수정시 MAIN_HEADER_HEIGHT 상수도 함께 수정 필요
export const NavigationStyle = styled.ul`
  display: flex;
  justify-content: center;
  min-height: 59px;
`;

export const NavigationItem = styled.li`
  ${({ theme }) => theme.adsTypo.bodyL}

  &.isActive > a > span {
    border-bottom-color: ${({ theme }) => theme.adsColor.black};
  }

  & > a {
    display: block;
    height: 100%;
    padding: 0 16px;

    & > span {
      display: block;
      height: 100%;
      box-sizing: border-box;
      padding: 17px 0 15px 0;
      border-bottom: 2px solid transparent;
    }

    &:hover > span {
      border-bottom-color: ${({ theme }) => theme.adsColor.black};
    }
  }
`;
