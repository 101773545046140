import * as React from "react";
import { FC, memo } from "react";

import Backdrop from "../../Backdrop/Backdrop";
import SpinnerIcon from "../SpinnerIcon";
import { LoadingSpinnerStyle } from "./style";

interface ILoadingSpinnerProps {
  className?: string;
}

const LoadingSpinner: FC<ILoadingSpinnerProps> = (props) => {
  const { className } = props;

  return (
    <LoadingSpinnerStyle className={className}>
      <Backdrop className="loading-spinner-backdrop" visible />

      <SpinnerIcon className="spinner-icon" />
    </LoadingSpinnerStyle>
  );
};

export default memo(LoadingSpinner);
