import styled from "@styles/themedComponents";

export const ButtonCoverStyle = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  text-align: inherit;
  color: inherit;
  outline: none;
  box-shadow: none;
`;
