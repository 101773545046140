import { IRowProps } from "./Row";
import styled from "@styles/themedComponents";

export const RowStyle = styled.div<IRowProps>`
  display: flex;
  align-items: center;
  flex-direction: ${({ direction }) => direction};
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;
