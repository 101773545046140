import * as React from "react";
import { useEffect } from "react";
import { ads_color, ICON_SIZE, StarFilled } from "@amondz/design-system";
import { RateStyle } from "./style";
import { RateProps } from "./type";

const Rate = (props: RateProps) => {
  const { id, className, amount, onChange, initialValue = 5, size = "small" } = props;

  const handleClickStar = (amount: number) => {
    onChange && onChange(amount);
  };

  useEffect(() => {
    handleClickStar(initialValue);
  }, []);

  return (
    <RateStyle aria-labelledby={id} className={className}>
      {Array(5)
        .fill(null)
        .map((item, index) => {
          const isActive = index + 1 <= amount;
          return (
            <div key={index} className="star" onClick={() => handleClickStar(index + 1)}>
              <StarFilled fill={isActive ? ads_color.black : ads_color.gray_05} size={ICON_SIZE[size]} />
            </div>
          );
        })}
    </RateStyle>
  );
};

export default Rate;
