import { useQuery } from "@tanstack/react-query";

import { eventApiV2, EventProductsFindResponseBodyDto } from "@amondz/apis-v2";
import { EventItemListRequest, EventItemListResponse } from "@amondz/types";
import { GIFT_FILTER, PRODUCT_LIST_PAGE_SIZE, PRODUCT_STATUS_V1, TODAY_DELIVERY_FILTER } from "@amondz/constants";
import { eventQueryKeys } from "./keys";

// todo: 상품 공통 타입 정의 필요
const convertResponseData = (data: EventProductsFindResponseBodyDto): EventItemListResponse => {
  return {
    totalCount: data.totalCount ?? 0,
    eventProducts: data.eventProducts.map((item) => {
      return {
        id: item.id,
        categoryId: item.productCategories[0].id,
        categoryName: item.productCategories[0].name,
        productCategoryList: item.productCategories,
        imgUrl: item.productMainAttachment.url,
        name: item.name,
        storeId: item.store.id,
        storeName: item.store.name,
        status: item.status === "soldOut" ? PRODUCT_STATUS_V1.SOLD_OUT : PRODUCT_STATUS_V1.NOW_ON_SALE,
        salesPrice: item.salesPrice,
        originPrice: item.originPrice,
        discountRate: item.discountRate,
        likeCount: item.likeCount,
        likeFlag: Number(item.isLike),
        isTodayDelivery: item.isTodayDelivery ? TODAY_DELIVERY_FILTER.TODAY_DELIVERY : TODAY_DELIVERY_FILTER.ENTIRE,
        isGift: item.isGift ? GIFT_FILTER.GIFT : GIFT_FILTER.ENTIRE,
      };
    }),
  };
};

const useEventItemList = (payload: EventItemListRequest) => {
  const filter = payload.filterState.reduce((acc, filterState) => {
    return {
      ...acc,
      ...filterState.value,
    };
  }, {});
  return useQuery({
    queryKey: eventQueryKeys.eventItemList(payload),
    queryFn: () =>
      eventApiV2.getEventItemList({
        eventId: payload.eventId,
        page: payload.page ? Number(payload.page) : 1,
        pageSize: PRODUCT_LIST_PAGE_SIZE,
        isTodayDelivery: !!payload.isTodayDelivery ? true : null,
        sortType: payload.sortType,
        ...filter,
      }),
    select: convertResponseData,
  });
};
export default useEventItemList;
