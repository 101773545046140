import * as React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useHomeTabList } from "@amondz/react-query";
import { HOME_TAB_TYPE } from "@amondz/constants";
import { HomeTab } from "@amondz/types";
import { GTM_EVENT, useGtmEventLogging } from "@amondz/gtm";
import { PAGE_NAME } from "@amondz/gtm/src/constants/pageName";
import { GlobalNavigationBarStyle, NavigationItem, NavigationStyle } from "./style";

const GlobalNavigationBar = (): React.JSX.Element => {
  const { data, error } = useHomeTabList();
  const router = useRouter();
  const { trigger } = useGtmEventLogging();

  const navigatePath = (navigationItem: HomeTab): string => {
    switch (navigationItem.linkType) {
      case HOME_TAB_TYPE.MAIN:
        return "/";
      case HOME_TAB_TYPE.SUB_PAGE:
        return `/home/${navigationItem.linkId}`;
    }
  };

  const handleClickItem = (homeTab: HomeTab, idx: number): void => {
    trigger(GTM_EVENT.SELECT_LOG, {
      itemName: homeTab.title,
      itemIndex: idx + 1,
      screenName: PAGE_NAME[router.pathname],
      contentType: "GNB",
    });
  };

  if (error) return <></>;

  return (
    <GlobalNavigationBarStyle>
      <NavigationStyle>
        {data?.map((homeTab, idx) => (
          <NavigationItem
            key={homeTab.id}
            className={classNames({ isActive: router.asPath.split("?")[0] === navigatePath(homeTab) })}
            onClick={(): void => handleClickItem(homeTab, idx)}
          >
            <Link href={navigatePath(homeTab)}>
              <span>{homeTab.title}</span>
            </Link>
          </NavigationItem>
        ))}
      </NavigationStyle>
    </GlobalNavigationBarStyle>
  );
};

export default GlobalNavigationBar;
