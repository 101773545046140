import { ProductReviewListRequestType } from "@amondz/apis-v2";
import { GetProductCustomerServiceRequestType } from "@amondz/apis-v1";

export const productQueryKeys = {
  base: ["product"] as const,
  like: (pageNumber: number, pageSize: number) => ["product", "like", pageNumber, pageSize],
  productDetail: (productId: number) => [...productQueryKeys.base, "detail", productId] as const,
  productDelivery: (productId: number) => [...productQueryKeys.base, "delivery", productId] as const,
  priceBenefits: (productId: number) => [...productQueryKeys.base, "benefits", productId] as const,
  productQnas: (params: { productId: number; page?: number; pageSize?: number }) => {
    const paramsArray = Object.values(params);
    return [...productQueryKeys.base, "qnas", ...paramsArray] as const;
  },
  productBasic: (productId: number) => [...productQueryKeys.base, "basic", productId] as const,
  // 추가적인 상품 정보에 대한 쿼리 키
  productAdditional: (productId: number) => [...productQueryKeys.base, "additional", productId] as const,
  // 상품 리뷰 정보 요청 쿼리키
  productReviews: (payload: ProductReviewListRequestType) => {
    const options = Object.values(payload);
    return [...productQueryKeys.base, "reviews", ...options] as const;
  },
  productReviewsInfinite: (payload: ProductReviewListRequestType) => {
    const options = Object.values(payload);
    return [...productQueryKeys.base, "reviews", "infinite", ...options] as const;
  },
  productCustomerService: (payload: GetProductCustomerServiceRequestType) =>
    [...productQueryKeys.base, { ...payload }, "customerService"] as const,
};
