import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REMOTE_CONFIG_KEY, useFirebaseRemoteConfig } from "@amondz/firebase";
import { ClickGtmEventLogging, GTM_EVENT } from "@amondz/gtm";

import { RootStateType } from "@store/modules";
import { searchModalStateType, searchModalVisible } from "@store/modules/search";
import { SEARCH_GRAY_BTN_24_IMAGE_PATH } from "@constants/static/images";
import {
  SearchBoxStyle,
  SearchContentStyle,
  SearchIconStyle,
  SearchTextStyle,
} from "@views/search/BeforeSearch/SearchBox/style";

interface ISearchBoxProps {
  className?: string;
}

const SearchBox: FC<ISearchBoxProps> = (props) => {
  const { className } = props;
  const { searchKeyword } = useSelector<RootStateType, searchModalStateType>(
    (state: RootStateType) => state.search.searchModalState,
  );
  const { getString } = useFirebaseRemoteConfig();

  const dispatch = useDispatch();

  let searchPlaceHolder = getString(REMOTE_CONFIG_KEY.SEARCH_PLACEHOLDER);

  /*
  const variation = useVariation(5);
  
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "development") {
    if (variation === "B") {
      searchPlaceHolder = "순금으로 검색하면 금이 나온다!";
    }
  }
   */

  return (
    <ClickGtmEventLogging event={GTM_EVENT.CLICK_SEARCH}>
      <SearchBoxStyle
        className={className}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(searchModalVisible({ isSearchModalVisible: true }));
        }}
      >
        <SearchContentStyle className="search-keyword-box">
          <SearchTextStyle className="search-keyword-text" isSearchKeywordExisted={!!searchKeyword}>
            {searchKeyword || searchPlaceHolder}
          </SearchTextStyle>
        </SearchContentStyle>
        <SearchIconStyle src={SEARCH_GRAY_BTN_24_IMAGE_PATH} />
      </SearchBoxStyle>
    </ClickGtmEventLogging>
  );
};

export default SearchBox;
