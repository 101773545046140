import styled from "@styles/themedComponents";
import CommonList from "@components/common/List";

interface IListStyle {
  calcGridColumns: string;
  column: number;
  rowGap?: number;
  columnGap?: number;
}
export const ListStyle = styled.ul<IListStyle>`
  display: grid;
  grid-template-columns: repeat(${({ column, calcGridColumns }) => `${column}, calc(${calcGridColumns})`});
  row-gap: ${({ rowGap = 0 }) => rowGap}px;
  column-gap: ${({ columnGap = 0 }) => columnGap}px;
`;

export const CommonListStyle = styled(CommonList)`
  .list-pagination {
    margin-top: 40px;
  }
`;
