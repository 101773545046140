import styled, { css } from "@styles/themedComponents";

const fontStyle = css`
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.6px;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    font-weight: 500;
  }
`;
export const CategoryStyle = styled.div`
  display: flex;
  gap: 44px;
  width: 964px;
  margin: 0 auto;
  padding-top: 32px;
`;

export const NormalCategoryListStyle = styled.div`
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px 24px;

    li {
      width: 136px;
      overflow: hidden;

      a {
        ${fontStyle};

        &:hover {
          font-weight: 500;
        }
      }
    }
  }
`;

export const EmphasisCategoryListStyle = styled.div`
  ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 32px;

    li {
      width: 80px;
      text-align: center;
      overflow: hidden;

      a {
        ${fontStyle};
        text-align: center;

        .emphasis-category-img {
          margin: 0 auto 8px;
        }
      }
    }
  }
`;
