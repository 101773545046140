import { brandApiV2, LikeBrandResponseDto } from "@amondz/apis-v2";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";

interface IUseUnLikeBrandProps {
  onSuccessCallback?: (data: { totalCount: number }, variable: number) => void;
  onErrorCallback?: (error: unknown) => void;
}
export const useUnLikeBrand = ({
  onSuccessCallback,
  onErrorCallback,
}: IUseUnLikeBrandProps): UseMutationResult<LikeBrandResponseDto, AxiosError, number, unknown> => {
  return useMutation({
    mutationFn: async (brandId: number) => brandApiV2.unlikeBrand(brandId),
    onSuccess: (data, variables) => {
      if (data && onSuccessCallback) {
        onSuccessCallback(data, variables);
      }
    },
    onError: (error) => {
      if (error && onErrorCallback) {
        onErrorCallback(error);
      }
    },
  });
};
