import useSWR from "swr";

import { categoryTreeFetcher } from "@swr/fetchers/product/categoryTreeFetcher";
import { CATEGORY_TREE_KEY } from "@swr/keys/productKeys";
import { IGetCategoryTreeRequest } from "@swr/types/productTypes";

const useCategoryTree = (data?: IGetCategoryTreeRequest) => {
  return useSWR(CATEGORY_TREE_KEY, () => categoryTreeFetcher(data));
};

export default useCategoryTree;
