import styled from "@styles/themedComponents";

export const LoadingSpinnerStyle = styled.div`
  .loading-spinner-backdrop {
    background-color: rgba(255, 255, 255, 0.65);
  }

  .spinner-icon {
    z-index: ${(props) => props.theme.zIndex.loadingSpinner};
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
