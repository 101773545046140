import { useQuery } from "@tanstack/react-query";
import { luckyDrawApiV2, LuckyDrawFindOneResponseDto } from "@amondz/apis-v2";
import { luckyDrawQueryKeys } from "./keys";

export const useLuckyDrawDetail = ({ code, isLoggedIn }: { code: string; isLoggedIn: boolean }) => {
  return useQuery<LuckyDrawFindOneResponseDto, Error>(
    luckyDrawQueryKeys.getDetail(code),
    () => luckyDrawApiV2.getDetail(code),
    {
      enabled: !!code && isLoggedIn,
    },
  );
};
