import React from "react";
import { IBaseIconProps } from "@amondz/types";

export const AmondzUSLogo = ({ className, width = 70, height = 12, fill }: IBaseIconProps) => {
  return (
    <svg
      className={className}
      role="img"
      aria-label="amondz-us-logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 70 12"
      fill={fill}
    >
      <defs>
        <path id="nps3x91c7a" d="M0 0h9.112v9.02H0z" />
        <path id="ydx0hq1qsc" d="M0 0h8.911v8.754H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M64.387 3.119h1.079V6.38c0 .291-.05.555-.147.793a1.716 1.716 0 0 1-1.082.999 2.702 2.702 0 0 1-.89.138c-.334 0-.632-.046-.894-.138a1.847 1.847 0 0 1-.662-.39 1.713 1.713 0 0 1-.416-.61 2.067 2.067 0 0 1-.147-.792V3.12h1.08v3.313c0 .322.086.574.26.756.175.181.435.272.78.272.343 0 .603-.09.777-.272.175-.182.262-.434.262-.756V3.119zM70 6.72c0 .222-.05.426-.151.613-.1.187-.241.348-.423.484-.181.136-.4.242-.658.318a3.02 3.02 0 0 1-.854.114c-.296 0-.593-.03-.892-.088a3.85 3.85 0 0 1-.825-.253l.243-.81c.2.086.426.16.674.22.249.06.504.09.766.09.28 0 .507-.051.683-.155a.494.494 0 0 0 .264-.45c0-.181-.084-.33-.251-.446-.168-.116-.425-.237-.77-.364a5.804 5.804 0 0 1-.654-.283 2.1 2.1 0 0 1-.478-.33 1.33 1.33 0 0 1-.297-.405 1.168 1.168 0 0 1-.105-.503c0-.464.175-.821.524-1.07.349-.25.817-.375 1.403-.375.301 0 .578.025.83.075.25.05.494.132.728.242l-.218.81a3.795 3.795 0 0 0-.616-.208 2.743 2.743 0 0 0-.657-.08c-.246 0-.444.042-.595.126a.415.415 0 0 0-.226.39c0 .08.013.15.038.211.025.06.071.117.138.17.067.053.155.107.264.16.109.052.247.112.414.177a9.6 9.6 0 0 1 .641.273c.204.096.384.207.54.333.157.126.282.272.378.439.094.166.142.358.142.575M59.726 11.622h-6.664v-1.195l4.216-5.625h-4.044v-1.69h6.32v1.196L55.44 9.933h4.286zM46.85 3.113c1.305 0 2.294.532 2.93 1.398V0h1.81v11.65h-1.81v-1.197c-.636.882-1.625 1.414-2.93 1.414-2.279 0-4.172-1.915-4.172-4.378 0-2.479 1.893-4.376 4.171-4.376m2.93 4.376c0-1.547-1.138-2.663-2.645-2.663-1.508 0-2.647 1.116-2.647 2.663 0 1.549 1.139 2.664 2.647 2.664 1.507 0 2.646-1.115 2.646-2.664M41.665 6.541v5.109h-1.81V6.723c0-1.264-.737-1.93-1.875-1.93-1.189 0-2.127.7-2.127 2.398v4.459h-1.81V3.328h1.81v1.065c.553-.864 1.457-1.28 2.596-1.28 1.876 0 3.216 1.265 3.216 3.428"
          fill="#000"
        />
        <g transform="translate(23.992 2.98)">
          <mask id="n3f39fobkb" fill="#fff">
            <use xlinkHref="#nps3x91c7a" />
          </mask>
          <path
            d="M7.423 3.946c.008-.77-.277-1.436-.806-1.878-.4-.334-.911-.51-1.476-.51-.933 0-1.897.48-2.578 1.29-1.137 1.346-1.168 3.188-.068 4.104.4.334.911.511 1.476.511.933 0 1.897-.482 2.578-1.29.555-.655.865-1.447.874-2.227M4.555 0c2.556 0 4.557 1.98 4.557 4.51 0 2.529-2.001 4.51-4.557 4.51C2.001 9.02 0 7.04 0 4.51 0 1.98 2.001 0 4.555 0"
            fill="#000"
            mask="url(#n3f39fobkb)"
          />
        </g>
        <path
          d="M22.864 6.491v5.16h-1.81V6.606c0-1.164-.636-1.814-1.624-1.814-1.072 0-1.825.683-1.825 2.197v4.66h-1.81V6.607c0-1.164-.585-1.814-1.574-1.814-1.021 0-1.86.683-1.86 2.197v4.66h-1.807V3.328h1.807v1c.537-.817 1.358-1.215 2.395-1.215 1.09 0 1.894.481 2.38 1.315.553-.866 1.44-1.315 2.563-1.315 1.891 0 3.165 1.297 3.165 3.378"
          fill="#000"
        />
        <g transform="translate(0 3.113)">
          <mask id="kb72k7c0md" fill="#fff">
            <use xlinkHref="#ydx0hq1qsc" />
          </mask>
          <path
            d="M7.102 4.376c0-1.547-1.138-2.663-2.646-2.663S1.809 2.83 1.809 4.376c0 1.549 1.139 2.664 2.647 2.664 1.508 0 2.646-1.115 2.646-2.664zM8.912.215v8.322h-1.81V7.34c-.636.882-1.625 1.414-2.93 1.414C1.893 8.754 0 6.839 0 4.376 0 1.897 1.893 0 4.172 0c1.305 0 2.294.532 2.93 1.398V.215h1.81z"
            fill="#000"
            mask="url(#kb72k7c0md)"
          />
        </g>
      </g>
    </svg>
  );
};

export default AmondzUSLogo;
