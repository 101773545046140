import { JSX, memo } from "react";

import { InlineMessageStyle } from "./style";

export interface InlineMessageProps {
  className?: string;
  text: string | undefined;
}

const InlineMessage = (props: InlineMessageProps): JSX.Element | null => {
  const { className, text } = props;

  if (!text) return null;

  return <InlineMessageStyle className={className}>{text}</InlineMessageStyle>;
};

export default memo(InlineMessage);
