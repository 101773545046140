// 이메일 정규식
export const REX_EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

// 이메일 정규식 - 회원정보 수정
export const REX_EMAIL_USER_MODIFY_PATTERN = /^([A-Z0-9._-]){2,}@([A-Z0-9.-]){2,}\.[A-Z]{2,6}$/i;

// 이메일 정규식 - 한글,공백 제외 정규식
export const REX_EMAIL_USER_MODIFY_EXCLUSION_PATTERN = /[ㄱ-ㅎㅏ-ㅣ가-힣~!#$%^&*()+|<>?:{}\s]/;

//새 비밀번호 정규식(영문,숫자,특수기홍 포함 9자 이상 15자 이하)
export const REX_PASSWORD_PATTERN =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*()_\-+=\[\]{}|\\;:'"<>,.?/₩])[A-Za-z\d~!@#$%^&*()_\-+=\[\]{}|\\;:'"<>,.?/₩]{8,15}$/;

// 비밀번호 - 한글 제외 정규식
export const REX_PASSWORD_EXCLUSION_PATTERN = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;

// 휴대 전화번호 정규식
export const REX_CELL_PHONE_PATTERN = /^01[016789]-?([0-9]{3,4})-?([0-9]{4})$/i;

// 숫자 정규식
export const REX_NUMBER_PATTERN = /^[0-9]+$/i;

// 상세 주소 정규식
export const REX_ADDRESS_LINE_2_PATTERN = /^[\sㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_().-]+$/i;

// 쿠폰 등록 정규식
export const REX_COUPON_REGISTRATION_PATTERN = /[^ㄱ-ㅎㅏ-ㅣ가-힣A-Z0-9]+/i;

// SMS 인증 코드 정규식
export const REX_SMS_AUTH_CODE_PATTERN = /^[0-9]{6,6}$/i;

// youtube url 정규식
export const REX_YOUTUBE_URL_PATTERN =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/i;

// 이모지 정규식
export const REX_EMOJI_PATTERN =
  /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
