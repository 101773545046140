import superFetch from "@amondz/apis-v1";
import {
  brandListKey,
  eventListKey,
  integrationListKey,
  productListKey,
  reviewListKey,
  searchBeforeAutoCompleteListKey,
  searchBeforeIntegrationListKey,
} from "@swr/keys/searchKeys";
import {
  searchBeforeAutoCompleteListRequestType,
  searchBrandListRequestType,
  searchEventListRequestType,
  searchIntegrationListRequestType,
  searchProductListRequestType,
  searchReviewListRequestType,
} from "@swr/types/searchTypes";

const searchAPI = {
  // 검색 전 통합 검색 결과 요청 API
  searchBeforeIntegrationList: () => {
    return superFetch.post(searchBeforeIntegrationListKey);
  },

  // 검색 중 자동완성 결과 요청 API
  searchBeforeAutoCompleteList: (data: searchBeforeAutoCompleteListRequestType) => {
    return superFetch.post(searchBeforeAutoCompleteListKey, data);
  },

  // 통합 검색 결과 요청 API
  integrationList: (data: searchIntegrationListRequestType) => {
    return superFetch.post(integrationListKey, data);
  },

  // 상품 검색 결과 요청 API
  productList: (data: searchProductListRequestType) => {
    return superFetch.post(productListKey, data);
  },

  // 브랜드 검색 결과 요청 API
  brandList: (data: searchBrandListRequestType) => {
    return superFetch.post(brandListKey, data);
  },

  // 상품 후기 검색 결과 요청 API
  reviewList: (data: searchReviewListRequestType) => {
    return superFetch.post(reviewListKey, data);
  },

  // 기획전 검색 결과 요청 API
  eventList: (data: searchEventListRequestType) => {
    return superFetch.post(eventListKey, data);
  },
};

export default searchAPI;
