import { createQueryKeys } from "@lukemorales/query-key-factory";
import { Response } from "@amondz/types";
import {
  brandAPI,
  brandAPI as brandApiV1,
  brandDetailRequestType,
  brandDetailType,
  brandInfoRequestType,
  brandInfoType,
  brandListDataType,
  brandListRequestType,
} from "@amondz/apis-v1";
import {
  brandApiV2,
  BrandsFindOneResponseDto,
  LikeBrandResponseType,
  LikeStoreList,
  LikeStoreListRequest,
} from "@amondz/apis-v2";

export const brandQuery = createQueryKeys("brand", {
  list: (params: brandListRequestType) => ({
    queryKey: [params],
    queryFn: (): Promise<Response<brandListDataType>> => brandApiV1.brandList(params),
  }),
  info: (params: brandInfoRequestType) => ({
    queryKey: [params],
    queryFn: (): Promise<brandInfoType> => brandAPI.brandInfo(params),
  }),
  products: (params: brandDetailRequestType) => ({
    queryKey: [params],
    queryFn: (): Promise<brandDetailType> => brandApiV1.brandDetail(params),
  }),
  detail: (id: number) => ({
    queryKey: [id],
    queryFn: (): Promise<BrandsFindOneResponseDto> => brandApiV2.getDetail(id),
  }),
  like: (id: number) => ({
    queryKey: [id],
    queryFn: (): Promise<LikeBrandResponseType> => brandApiV2.likeBrand(id),
  }),
  unlike: (id: number) => ({
    queryKey: [id],
    queryFn: (): Promise<LikeBrandResponseType> => brandApiV2.unlikeBrand(id),
  }),
  favorites: ({ pageNumber, pageSize }: LikeStoreListRequest) => ({
    queryKey: [pageNumber, pageSize],
    queryFn: (): Promise<LikeStoreList> => brandApiV2.likeStoreList({ pageNumber, pageSize }),
  }),
});
