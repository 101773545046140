import styled from "@styles/themedComponents";

export const FooterStyle = styled.footer`
  margin: 0 auto;
  overflow: hidden;
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  letter-spacing: -0.3px;
  color: ${(props) => props.theme.color.amondz_black};
  background-color: ${(props) => props.theme.color.amondz_white};
  border-top: 1px solid ${(props) => props.theme.color.gray_07};
`;
