import styled from "@styles/themedComponents";
import { LNB_ANIMATION_SPEED } from "../constants";

export const LNBStyle = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 308px;
  background-color: ${(props) => props.theme.color.amondz_white};
  box-shadow: inset 0 -1px 0 0 ${(props) => props.theme.color.gray_07};
  z-index: 0;
  opacity: 0;
  transition: opacity ${LNB_ANIMATION_SPEED}ms;
`;
