import * as React from "react";
import { FC, Fragment, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Link from "next/link";
import Router, { useRouter } from "next/router";

import { RootStateType } from "@store/modules";
import { searchModalStateType } from "@store/modules/search";
import { twoButtonModalToggle } from "@store/modules/base";
import { LogOutAsync } from "@store/modules/auth";
import Icon from "@components/common/Icon";
import { HEADER_CART_BTN_IMAGE_PATH } from "@constants/static/images";
import {
  INTRO_PAGE_URL_PATH,
  SIGN_IN_PAGE_URL_PATH,
  MY_PAGE_URL_PATH,
  PAYMENT_CART_URL_PATH,
} from "@constants/url/internalUrlConstants";
import { LOG_OUT_INFO_MODAL_CONTENTS, REQUIRED_LOG_IN_INFO_MODAL_CONTENTS } from "@constants/service/auth/modal";
import AmondzLogo from "@components/icon/AmondzLogo";
import SearchModal from "@views/search/modal/SearchModal/SearchModal";
import SearchBox from "@views/search/BeforeSearch/SearchBox";
import GlobalNavigationBar from "@components/base/header/Header/GlobalNavigationBar";
import MainMenu from "./MainMenu";
import { GlobalStyle, HeaderStyled } from "./style";

export interface HeaderProps {
  /**
   * 유저 로그인 여부
   */
  isLoggedIn: boolean;
  /**
   * 장바구니에 담긴 상품 수
   */
  cartCount: number | null;
}

const Header: FC<HeaderProps> = (props: HeaderProps) => {
  const { isLoggedIn, cartCount } = props;
  const { isSearchModalVisible } = useSelector<RootStateType, searchModalStateType>(
    (state: RootStateType) => state.search.searchModalState,
  );
  const router = useRouter();
  const dispatch = useDispatch();

  const rtnUrl = useMemo(() => {
    return typeof router.query.rtnUrl === "string" ? router.query.rtnUrl : router.asPath;
  }, [router]);

  // 로그아웃 버튼 클릭
  const onClickLogOutBtn = useCallback(
    () =>
      dispatch(
        twoButtonModalToggle({
          visible: true,
          msg: LOG_OUT_INFO_MODAL_CONTENTS.MSG,
          onClickRightBtn: () => dispatch(LogOutAsync.request({ isApiRequest: true })),
        }),
      ),
    [],
  );

  const onRequiredAuthInfoModal = useCallback(
    (rtnType: 0 | 1) => () => {
      let url = "";
      if (rtnType === 0) {
        // 장바구니로 이동
        url = PAYMENT_CART_URL_PATH;
      } else if (rtnType === 1) {
        // 마이페이지로 이동
        url = MY_PAGE_URL_PATH;
      }
      dispatch(
        twoButtonModalToggle({
          visible: true,
          msg: REQUIRED_LOG_IN_INFO_MODAL_CONTENTS.MSG,
          subMsg: REQUIRED_LOG_IN_INFO_MODAL_CONTENTS.SUB_MSG,
          onClickRightBtn: () => Router.push(`${SIGN_IN_PAGE_URL_PATH}?rtnUrl=${encodeURIComponent(url)}`),
        }),
      );
    },
    [],
  );

  // 장바구니 갯수 계산
  const calcCartCount = useMemo(() => Math.min(99, cartCount || 0), [cartCount]);

  return (
    <HeaderStyled>
      <div className="header-wrap">
        <div className="amondz-log-section">
          <Link href={INTRO_PAGE_URL_PATH}>
            <AmondzLogo width={134} height={28} />
          </Link>
        </div>

        <MainMenu className="main-menu" />
        <SearchBox className="search-menu" />
        <ul className="header-menu">
          <ul className="auth-menu">
            {isLoggedIn ? (
              <Fragment>
                <li className="logout-btn">
                  <a onClick={onClickLogOutBtn}>로그아웃</a>
                </li>
                <li className="mypage-btn">
                  <Link href={MY_PAGE_URL_PATH}>마이</Link>
                </li>
              </Fragment>
            ) : (
              <Fragment>
                <li className="login-btn">
                  <Link href={`${SIGN_IN_PAGE_URL_PATH}?rtnUrl=${encodeURIComponent(rtnUrl)}`}>로그인</Link>
                </li>
                <li className="mypage-btn" onClick={onRequiredAuthInfoModal(1)}>
                  마이
                </li>
              </Fragment>
            )}
          </ul>

          {isLoggedIn ? (
            <li className="user-menu">
              <ul>
                <li className="cart-btn">
                  <Link href={PAYMENT_CART_URL_PATH}>
                    <Icon src={HEADER_CART_BTN_IMAGE_PATH} />
                    <div className="cart-count">
                      <span>{calcCartCount}</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            <li className="cart-btn">
              <Icon src={HEADER_CART_BTN_IMAGE_PATH} onClick={onRequiredAuthInfoModal(0)} />
            </li>
          )}
        </ul>
        {isSearchModalVisible && (
          <>
            <SearchModal />
            <GlobalStyle />
          </>
        )}
      </div>
      <GlobalNavigationBar />
    </HeaderStyled>
  );
};

export default Header;
