import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import {
  couponApiV2,
  CouponGeneratedsCreateBulkResponseDto,
  CouponGeneratedsCreateOneResponseDto,
  ProductDownloadableCouponDto,
  ProductDownloadableCouponsResponseDto
} from "@amondz/apis-v2";
import { CouponBulkRequestType } from "@amondz/apis-v2/src/coupon/type";
import { AxiosError } from 'axios';
import { cloneDeep } from "lodash";
import { couponQueryKeys } from "./keys";
import { productQueryKeys } from "../product";

type CouponId = number;

export interface ErrorData<ErrorObj> {
  errors: ErrorObj[];
}

interface CouponMutateOptions<ErrorObj> extends UseMutationOptions<CouponGeneratedsCreateOneResponseDto, AxiosError<ErrorData<{ code: string; message: string }>>, CouponId> {
  productId: number;
}

interface CouponBulkMutateOptions<ErrorObj> extends UseMutationOptions<CouponGeneratedsCreateBulkResponseDto, AxiosError<ErrorData<{ code: string; message: string }>>, CouponBulkRequestType> {
  productId: number;
}

export const useCouponMutate = <ErrorObj>(payload: CouponMutateOptions<ErrorObj>) => {
  const { productId, ...restOptions } = payload;
  const queryClient = useQueryClient();

  return useMutation(
    couponQueryKeys.downloadCoupon(),
    (couponId: CouponId) => couponApiV2.downloadSingleCoupon({ couponId }),
    {
      ...restOptions,
      onSuccess: (data: CouponGeneratedsCreateOneResponseDto) => {
        queryClient.setQueryData<ProductDownloadableCouponsResponseDto>(couponQueryKeys.coupons(productId), (cachedData) => {
          if (!cachedData) return { productDownloadableCoupons: [] };

          const clonedData = cloneDeep(cachedData);

          const foundCouponIndex = clonedData.productDownloadableCoupons.findIndex(
            (coupon: ProductDownloadableCouponDto) => coupon.coupon.id === data.couponGenerated.coupon.id
          );
          if (foundCouponIndex !== -1) {
            clonedData.productDownloadableCoupons[foundCouponIndex].coupon.availableCount = data.couponGenerated.coupon.availableCount;
            clonedData.productDownloadableCoupons[foundCouponIndex].coupon.canDownload = data.couponGenerated.coupon.canDownload;
            clonedData.productDownloadableCoupons[foundCouponIndex].coupon.latestAvailableCouponGenerated = {
              id: data.couponGenerated.id,
              startDate: data.couponGenerated.startDate,
              endDate: data.couponGenerated.endDate,
            };
          }
          return clonedData;
        });
        queryClient.invalidateQueries(productQueryKeys.priceBenefits(productId));
      },
    }
  );
};

export const useCouponBulkMutate = <ErrorObj>(payload: CouponBulkMutateOptions<ErrorObj>) => {
  const { productId, ...restOptions } = payload;
  const queryClient = useQueryClient();

  return useMutation(
    couponQueryKeys.downloadCouponBulk(),
    (couponIdObjects: CouponBulkRequestType) => couponApiV2.downloadBulkCoupons(couponIdObjects),
    {
      ...restOptions,
      onSuccess: (data: CouponGeneratedsCreateBulkResponseDto) => {
        queryClient.setQueryData<ProductDownloadableCouponsResponseDto>(
          couponQueryKeys.coupons(productId),
          (cachedData) => {
            if (!cachedData) return { productDownloadableCoupons: [] };

            const clonedData = cloneDeep(cachedData);

            data.couponGenerateds.forEach((generatedCoupon) => {
              const foundCouponIndex = clonedData.productDownloadableCoupons.findIndex(
                (coupon: ProductDownloadableCouponDto) => coupon.coupon.id === generatedCoupon.coupon.id
              );

              if (foundCouponIndex !== -1) {
                clonedData.productDownloadableCoupons[foundCouponIndex].coupon.availableCount = generatedCoupon.coupon.availableCount;
                clonedData.productDownloadableCoupons[foundCouponIndex].coupon.canDownload = generatedCoupon.coupon.canDownload;
                clonedData.productDownloadableCoupons[foundCouponIndex].coupon.latestAvailableCouponGenerated = {
                  id: generatedCoupon.id,
                  startDate: generatedCoupon.startDate,
                  endDate: generatedCoupon.endDate,
                };
              }
            });

            return clonedData;
          }
        );
        queryClient.invalidateQueries(productQueryKeys.priceBenefits(productId));
      },
    }
  );
};
