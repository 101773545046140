import styled from "@styles/themedComponents";

export const RecentSearchWordListStyle = styled.ul`
  .recent-search-word-list {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.5px;
    height: 44px;
    display: flex;
    align-items: center;

    a {
      position: relative;
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .delete-icon {
      position: absolute;
      right: 0;
      width: 16px;
      height: 16px;
    }
  }
`;

export const RecentSearchWord = styled.span`
  line-height: 24px;
  display: inline-block;
  padding: 10px 0;
  width: 100%;
`;
