import * as React from "react";
import { ChangeEventHandler, FC, memo, ReactNode, useCallback } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { isNil } from "lodash";

import InlineMessage from "../InlineMessage";
import Icon from "../Icon";
import { CLEAR_INPUT_BTN_IMAGE_PATH } from "@constants/static/images";
import { InputStyle } from "./style";

export interface InputProps {
  className?: string;
  /** react-hook-form name 속성 */
  name: string;
  /** input 타입 */
  type: string;
  /** 반드시 입력 받을지 유무 판단 */
  required?: boolean;
  /** 최소 입력 글자수 */
  minLength?: number;
  /** 최대 입력 글자수 */
  maxLength?: number;
  /** 최솟값 */
  min?: number;
  /** 최댓값 */
  max?: number;
  /** register 를 호출할 때 지정하는 유효성 검사 규칙과 같은 포맷 */
  rules?: any;
  /** onFocus 이벤트 등록 */
  onFocus?: any;
  /** onBlur 이벤트 등록 */
  onBlur?: any;
  /** onChange 이벤트 등록 */
  onChange?: any;
  /** 읽기전용 유무 */
  readOnly?: boolean;
  /** input label 설정 */
  label?: string | ReactNode;
  /** 입력 값이 없을 경우 초기 값 */
  placeholder?: string;
  defaultValue?: string | number | readonly string[];
  /** input 버튼 */
  addOn?: ReactNode;
  innerRef?: any;
  /** input 활성화 여부 */
  disabled?: boolean;
  isShowClearBtn?: boolean;
  onClickWrapper?: () => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  /** autoFocus 활성화 여부 */
  autoFocus?: boolean;
}

const Input: FC<InputProps> = (props: InputProps) => {
  const {
    className = "",
    name,
    type,
    required,
    minLength,
    maxLength,
    min,
    max,
    rules,
    onFocus,
    onBlur,
    onChange,
    readOnly,
    label,
    placeholder,
    defaultValue,
    addOn,
    innerRef,
    disabled,
    isShowClearBtn = true,
    onClickWrapper,
    onKeyPress,
    autoFocus = false,
  } = props;
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];

  const formatValue = (value?: string) => {
    if (value && type === "number") {
      // 타입이 숫자인 경우, 숫자이외의 다른 문자는 입력하지 못하도록 함
      let newValue = Number(value.replace(/[^0-9]/g, ""));

      if (!isNil(min) && min > newValue) {
        // 최소값이 있으 경우, 최소 값보다 낮으면 최소값으로 적용함
        newValue = min;
      } else if (!isNil(max) && max < newValue) {
        // 최대값이 있을 경우, 최대 값보다 높으면 최대값으로 적용함
        newValue = max;
      }

      return newValue;
    }

    // 한글 입력 오류 해결(maxlength 초과해도 써지는 오류)
    if (value && maxLength && value.length > maxLength) {
      return value.slice(0, maxLength);
    }

    return value;
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = formatValue(e.target.value);
    if (newValue) e.target.value = String(newValue);
    setValue(name, newValue);
    if (onChange) onChange(e);
  };

  const onInputFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  const onInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur],
  );

  /**
   * 가상키보드에서 리턴키를 누르면 가상키보드 아래로 사라지게 함
   */
  const onInputKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (onKeyPress) {
        onKeyPress(e);
      } else {
        if (e.key === "Enter") {
          e.preventDefault();
          e.currentTarget.blur();
        }
      }
    },
    [onKeyPress],
  );

  const { ref, ...rest } = register(name, {
    onChange: handleChange,
    onBlur: onInputBlur,
    ...rules,
    shouldUnregister: true,
  });

  return (
    <InputStyle className={className} onClick={onClickWrapper}>
      {label && (
        <label htmlFor={name}>
          {label}
          {required && <span className="required">*</span>}
        </label>
      )}

      <div>
        <input
          onKeyPress={onInputKeyPress}
          //type number의 경우 한글이 입력되는 문제가 있어서 text로 type을 받고 onChange시에 정규식으로 숫자로 변환
          type={type === "number" ? "text" : type}
          minLength={minLength}
          maxLength={maxLength}
          min={min}
          max={max}
          disabled={disabled}
          onFocus={onInputFocus}
          readOnly={readOnly}
          placeholder={placeholder}
          defaultValue={defaultValue}
          ref={(el) => {
            ref(el);
            if (innerRef) innerRef.current = el;
          }}
          {...rest}
          autoFocus={autoFocus}
        />

        {addOn && <span className="add-on">{addOn}</span>}

        {isShowClearBtn && !addOn && getValues(name) && (
          // isShowClearBtn && !addOn && getValues(name) && isFocus && (
          <Icon
            className="clear-input-btn"
            src={CLEAR_INPUT_BTN_IMAGE_PATH}
            onClick={() => setValue(name, null, { shouldValidate: true })}
          />
        )}

        <ErrorMessage errors={errors} name={name} render={({ message }) => <InlineMessage text={message} />} />
      </div>
    </InputStyle>
  );
};

export default memo(Input);
