import { DeviceSize } from "@styles/themedComponents";

// 브라우저 너비에 따른 메인 해더 높이
export const MAIN_HEADER_HEIGHT = {
  [DeviceSize.SSR]: 0,
  [DeviceSize.DESKTOP]: 132,
  [DeviceSize.TABLET]: 56,
  [DeviceSize.PHONE]: 56,
};

export const MAIN_HEADER_HEIGHT_WITHOUT_GNB = 73;
