import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import produce from "immer";
import { Response } from "@amondz/types";
import { brandAPI, brandListRequestType, LikeBrandResponse, brandListDataType, BrandItem } from "@amondz/apis-v1";
import { LIKE_STATUS } from "@amondz/constants";
import { brandQuery } from "./queries";

export function useBrandList(
  payload: brandListRequestType & { onLikeSuccess: (res: LikeBrandResponse, store: BrandItem) => void },
): {
  data: brandListDataType | undefined;
  likeStore: (store: BrandItem) => void;
  mutateLoading: boolean;
} {
  const queryClient = useQueryClient();
  const { data: response } = useQuery(brandQuery.list(payload));
  const { mutate, isLoading: mutateLoading } = useMutation({
    mutationFn: (store: BrandItem) => brandAPI.likeBrand({ storeId: store.storeId }),
    onMutate: async (store: BrandItem) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(brandQuery.list(payload).queryKey);

      // Snapshot the previous value
      const previousData: Response<brandListDataType> | undefined = queryClient.getQueryData(
        brandQuery.list(payload).queryKey,
      );

      if (previousData) {
        // Optimistically update to the new value
        queryClient.setQueryData(
          brandQuery.list(payload).queryKey,
          produce(previousData, (draft) => {
            const target = draft.data?.allBrandList.find((item) => item.storeId === store.storeId);
            if (!target) return;
            target.likeFlag = target.likeFlag === LIKE_STATUS.LIKE ? LIKE_STATUS.UN_LIKE : LIKE_STATUS.LIKE;
          }),
        );
      }

      // Return a context
      return { previousData, store };
    },
    onSuccess: payload.onLikeSuccess,
    onError: (err, targetId, context) => {
      if (!context) return;
      queryClient.setQueryData(brandQuery.list(payload).queryKey, context.previousData);
    },
  });

  return { likeStore: mutate, data: response?.data, mutateLoading };
}
