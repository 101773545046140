import styled, { createGlobalStyle, css } from "@styles/themedComponents";

export const GlobalStyle = createGlobalStyle`

`;

const menuAnchorStyle = css`
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: ${(props) => props.theme.color.gray_01};
  cursor: pointer;
`;

export const HeaderStyled = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.zIndex.header};
  width: 100%;
  background-color: ${(props) => props.theme.color.amondz_white};

  .header-wrap {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 64px;
    max-width: 1920px;
    height: 73px;
    position: relative;
    box-shadow: 0 1px 0 0 ${(props) => props.theme.adsColor.gray_02};
    z-index: 1;

    .amondz-log-section {
      line-height: 0;

      .amondz-log {
        width: 139px;
        height: 37px;
        cursor: pointer;
      }
    }

    .search-menu {
      margin-right: auto;
      margin-left: auto;
    }

    .header-menu {
      display: flex;
      align-items: center;
      margin-left: 191px;

      .auth-menu {
        height: 24px;
        margin-right: 24px;

        &,
        & > ul {
          display: flex;

          li {
            &:first-child {
              margin-right: 24px;
            }
            &,
            a {
              ${menuAnchorStyle}
            }
          }
        }
      }

      .user-menu {
        display: inline-block;

        & > ul {
          & > * {
            vertical-align: middle;
            display: inline-block;
          }

          a {
            display: inline-block;
            height: 100%;
          }

          .mypage-btn {
            margin-right: 20px;
            cursor: pointer;

            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      .cart-btn {
        height: 24px;
        cursor: pointer;

        .cart-count {
          position: absolute;
          top: 35px;
          right: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          color: ${(props) => props.theme.color.amondz_white};
          background: ${(props) => props.theme.color.amondz_black};
          font-family: ${(props) => props.theme.fonts.barlow};
          border-radius: 50%;

          span {
            font-size: 11px;
            font-weight: bold;
          }
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .header-wrap {
      .search-menu {
        margin-left: auto;
        margin-right: auto;
      }
      .header-menu {
        margin-left: 0;
      }
    }
  }
`;
